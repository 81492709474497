import { Moment } from 'moment';
import { ISOCountryCode } from 'app/shared/model/enumerations/iso-country-code.model';
import { EclassAttributeType } from 'app/shared/model/enumerations/eclass-attribute-type.model';
import { EclassDataType } from 'app/shared/model/enumerations/eclass-data-type.model';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
export interface IAttribute {
  id?: number;
  supplier?: string;
  idPR?: string;
  identifier?: string;
  versionNumber?: number;
  versionDate?: string;
  revisionNumber?: number;
  prefferedName?: string;
  shortName?: string;
  definition?: string;
  sourceOfDefinition?: string;
  note?: string;
  remark?: string;
  formularSymbol?: string;
  irdiUN?: string;
  iSOLanguageCode?: string;
  iSOCountryCode?: ISOCountryCode;
  category?: string;
  attributeType?: EclassAttributeType;
  definitionClass?: string;
  dataType?: EclassDataType;
  digitsBeforeComma?: number;
  digitsAfterComma?: number;
  numberOfCharacters?: number;
  irdiPR?: string;
  currencyAlphaCode?: string;
  possibleValues?: Array<IEclassValue>;
  fromParent?: boolean;
}

export const defaultValue: Readonly<IAttribute> = {};
