import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import locale, { LocaleState } from './locale';
import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
// prettier-ignore
import classification, {
  ClassificationState
} from 'app/entities/classification/classification.reducer';
// prettier-ignore
import keyword, {
  KeywordState
} from 'app/entities/keyword/keyword.reducer';
// prettier-ignore
import attribute, {
  AttributeState
} from 'app/entities/attribute/attribute.reducer';
// prettier-ignore
import eclassValue, {
  EclassValueState
} from 'app/entities/eclass-value/eclass-value.reducer';
// prettier-ignore
import unit, {
  UnitState
} from 'app/entities/unit/unit.reducer';
// prettier-ignore
import classificationAttributeXref, {
  ClassificationAttributeXrefState
} from 'app/entities/classification-attribute-xref/classification-attribute-xref.reducer';
// prettier-ignore
import classificationAttributeValueXref, {
  ClassificationAttributeValueXrefState
} from 'app/entities/classification-attribute-value-xref/classification-attribute-value-xref.reducer';
// prettier-ignore
import specification, {
  SpecificationState
} from 'app/entities/specification/specification.reducer';
// prettier-ignore
import specificationAttributeData, {
  SpecificationAttributeDataState
} from 'app/entities/specification-attribute-data/specification-attribute-data.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly locale: LocaleState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly activate: ActivateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  readonly classification: ClassificationState;
  readonly keyword: KeywordState;
  readonly attribute: AttributeState;
  readonly eclassValue: EclassValueState;
  readonly unit: UnitState;
  readonly classificationAttributeXref: ClassificationAttributeXrefState;
  readonly classificationAttributeValueXref: ClassificationAttributeValueXrefState;
  readonly specification: SpecificationState;
  readonly specificationAttributeData: SpecificationAttributeDataState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const rootReducer = combineReducers<IRootState>({
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  register,
  activate,
  passwordReset,
  password,
  settings,
  classification,
  keyword,
  attribute,
  eclassValue,
  unit,
  classificationAttributeXref,
  classificationAttributeValueXref,
  specification,
  specificationAttributeData,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar,
});

export default rootReducer;
