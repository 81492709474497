import React, { useState, createRef } from 'react';
import axios from 'axios';
import SelectSearch from 'react-select-search';
import { IClassification } from 'app/shared/model/classification.model';

const apiUrl = 'api/classifications';

export const EclassClassificationSelect = props => {
  const { level, setSelectedClassification, setDatasheetList, selectedClassifcationCode } = props;
  const [levelItemList, setLevelItemList] = useState([]);
  const [levelList, setLevelList] = useState([]);
  const [levelCode, setLevelCode] = useState('');
  const [lastLevelCode, setLastLevelCode] = useState('');

  const [levelFilter, setLevelFilter] = useState('');

  const placeholder = 'Ebene ' + level + ' auswählen (' + levelCode + ')';
  const levelId = 'level' + level;
  const levelSearchValue = selectedClassifcationCode?.substring(0, level * 2 - 2);
  const selectClassificationRef = createRef<React.Component<{}, {}, any>>();

  React.useEffect(() => {
    const levelCheckStart = level * 2;
    const checkForCodeChange = selectedClassifcationCode.substring(levelCheckStart - 2, levelCheckStart);
    const currentCheckLevel = levelCode.substring(levelCheckStart - 2, levelCheckStart);

    // eslint-disable-next-line no-console
    console.log(
      'eclass-main-classification-select Level compare',
      currentCheckLevel,
      checkForCodeChange,
      selectedClassifcationCode,
      levelCode,
      lastLevelCode,
      levelSearchValue
    );

    if (
      (selectedClassifcationCode &&
        !levelSearchValue.endsWith('00') &&
        !levelSearchValue.endsWith('XX') &&
        checkForCodeChange !== currentCheckLevel) ||
      (selectedClassifcationCode !== lastLevelCode && !levelSearchValue.endsWith('00'))
    ) {
      // something changed, so we want to reload the level
      // eslint-disable-next-line no-console
      // console.log('eclass-main-classification-select Level a', level, levelSearchValue, selectedClassifcationCode);

      const requestUrl = `${apiUrl}/tree/` + level + `/` + levelSearchValue + `%25`;

      axios.get<IClassification[]>(requestUrl).then(response => {
        let resultLevelList = response.data.map(function (x) {
          const responseLevelCode = x.codedName.substring(0, levelCheckStart);

          if (responseLevelCode.substring(levelCheckStart - 2, levelCheckStart) === '00') {
            // setSelectedClassification(x);
            setDatasheetList([]);
            return;
          } else {
            // if we find the current value while iterating, then set it
            let setLevelTo = 4;
            if (selectedClassifcationCode.endsWith('00')) {
              setLevelTo = 3;
            }
            if (selectedClassifcationCode.endsWith('0000')) {
              setLevelTo = 2;
            }
            if (selectedClassifcationCode.endsWith('000000')) {
              setLevelTo = 1;
            }
            if (+level === setLevelTo) {
              // setLevelCode(selectedClassifcationCode.substring(0,level*2));
              if (selectedClassifcationCode.startsWith(responseLevelCode)) {
                // eslint-disable-next-line no-console
                console.log(
                  'eclass-main-classification-select iterating loaded list INNER',
                  responseLevelCode,
                  selectedClassifcationCode,
                  level,
                  setLevelTo
                );
                setSelectedClassification(x);
              }
            }

            return { value: responseLevelCode, name: responseLevelCode.substring(levelCheckStart - 2) + ' - ' + x.prefferedName };
          }
        });
        resultLevelList = resultLevelList.filter(function (e) {
          return e;
        });
        let levelNumber: number = +levelSearchValue;
        if (Number.isNaN(levelNumber)) {
          levelNumber = +levelSearchValue.substring(0, levelSearchValue.length - 2);
        }
        const responseItemList = response.data;

        // eslint-disable-next-line no-console
        console.log('eclass-main-classification-select CHECK', level, levelNumber, levelCode, levelSearchValue);
        if (levelNumber >= 90) {
          const updatedSelectedClassificationCode = selectedClassifcationCode.substring(0, level * 2 - 2) + 'XX';
          // eslint-disable-next-line no-console
          console.log(
            'eclass-main-classification-select push',
            level,
            levelNumber,
            selectedClassifcationCode,
            updatedSelectedClassificationCode
          );
          resultLevelList.push({
            value: updatedSelectedClassificationCode,
            name: 'Klassifikation hinzufügen' + ' (' + updatedSelectedClassificationCode + ')',
          });
          const newClassification = { codedName: updatedSelectedClassificationCode, prefferedName: 'New' } as IClassification;
          responseItemList.push(newClassification);
        }
        setDatasheetList([]);
        setLevelList(resultLevelList);
        setLevelItemList(responseItemList);
        setLastLevelCode(selectedClassifcationCode);
      });
      setLevelCode(selectedClassifcationCode.substring(0, level * 2));
    } else {
      // eslint-disable-next-line no-console
      console.log('eclass-main-classification-select RESET', level, levelSearchValue, levelCode, selectedClassifcationCode);
      if (!selectedClassifcationCode.startsWith(levelCode)) {
        setLevelList([]);
        setLevelItemList([]);
      }
    }
  }, [selectedClassifcationCode]);

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('eclass-main-classification-select levelItemList init', level, selectedClassifcationCode, levelItemList);

    if (selectedClassifcationCode && (selectedClassifcationCode === '00' || selectedClassifcationCode === 'XX')) {
      const checkForCode = selectedClassifcationCode.substring(0, level * 2).padEnd(8, '0');
      // eslint-disable-next-line no-console
      console.log('eclass-main-classification-select levelItemList checkForCode', selectedClassifcationCode, checkForCode);

      const levelClassification = levelItemList.find(o => o.codedName === checkForCode);
      // eslint-disable-next-line no-console
      console.log('eclass-main-classification-select levelItemList ', levelClassification, selectedClassifcationCode, levelItemList);

      if (levelClassification) {
        setSelectedClassification(levelClassification);
      }
    }

    setLevelCode(selectedClassifcationCode.substring(0, level * 2));
  }, [levelItemList]);

  const selectLevelCode = code => {
    if (code) {
      if (code.endsWith('XX')) {
        // this is a new level
        const newClassification = { codedName: code, prefferedName: 'bitte eingeben' } as IClassification;
        setSelectedClassification(newClassification);
      } else {
        const checkForCode = code.substring(0, level * 2).padEnd(8, '0');
        const levelClassification = levelItemList.find(o => o.codedName === checkForCode);
        if (levelClassification) {
          setSelectedClassification(levelClassification);
        }
        setLevelFilter('');
        setLevelList(
          levelItemList.map(function (x) {
            const levelCheckStart = level * 2;
            const responseLevelCode = x.codedName.substring(0, levelCheckStart);
            return { value: responseLevelCode, name: responseLevelCode.substring(levelCheckStart - 2) + ' - ' + x.prefferedName };
          })
        );
        if (selectClassificationRef) {
          selectClassificationRef?.current['classList'].remove('is-loading');
        }
      }
    }
  };

  function queryNewValues(query, resolve, reject) {
    if (query !== levelFilter) {
      const levelCheckStart = level * 2;
      const filteredList = levelItemList.filter(opt => opt.prefferedName.indexOf(query) !== -1 || opt.codedName.indexOf(query) !== -1);
      setLevelFilter(query);
      // eslint-disable-next-line no-console
      console.log('eclass-main-classification-select queryNewValues', query, filteredList);
      return Promise.resolve(
        setLevelList(
          filteredList.map(function (x) {
            const responseLevelCode = x.codedName.substring(0, levelCheckStart);
            return { value: responseLevelCode, name: responseLevelCode.substring(levelCheckStart - 2) + ' - ' + x.prefferedName };
          })
        )
      ).catch(reject);
    }
  }

  return (
    <React.Fragment>
      <SelectSearch
        id={levelId}
        options={levelList}
        ref={selectClassificationRef}
        onChange={selectLevelCode}
        search={true}
        placeholder={placeholder}
        value={levelCode}
        debounce={500}
        getOptions={query => {
          return new Promise((resolve, reject) => {
            if (query) {
              // eslint-disable-next-line no-console
              console.log('eclass-specification-search: getOptions', query);
              queryNewValues(query, resolve, reject);
            }
          });
        }}
      />
    </React.Fragment>
  );
};

export default EclassClassificationSelect;
