import React, { useState } from 'react';
import axios from 'axios';
import { FormGroup, Label, Input, FormText, Tooltip, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './eclass-specification-attribute-item.scss';

export const EclassSpecificationAttributeItem = props => {
  const { specificationAttributeData, selectedSpecification, inProgress } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(specificationAttributeData.irdiSD.versionNumber === 0);
  const [classificationList, setClassificationList] = useState([]);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(specificationAttributeData?.irdiPR.irdiPR)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch(err => {
        // console.log(err);
      });
  };

  const inputAttributes = {
    type: 'text',
    name: specificationAttributeData?.id,
    id: specificationAttributeData?.id,
    defaultValue: specificationAttributeData.data,
    bsSize: 'sm',
    placeholder: 'Wert eingeben',
    checked: false,
  };

  React.useEffect(() => {
    if (selectedSpecification?.versionNumber === 0) {
      setIsReadOnly(true);
    } else {
      setIsReadOnly(false);
    }
  }, [inProgress]);

  // load possible values for CLASSIFICATION_LIST
  React.useEffect(() => {
    if (specificationAttributeData?.irdiPR.dataType === 'CLASSIFICATION_LIST') {
      // eslint-disable-next-line no-console
      console.log('eclassSpecificationAttributeItem CLASSIFICATION_LIST', specificationAttributeData);
      // axios get something like: http://localhost:9000/api/classifications/tree/4/980101%
      const level = specificationAttributeData.irdiPR.definitionClass.length / 2 + 1;

      const requestUrl = encodeURI(`/api/classifications/tree/` + level + `/` + specificationAttributeData.irdiPR.definitionClass + `%`);
      axios.get(requestUrl).then(response => {
        const returnList = response.data;
        setClassificationList(returnList);
        return returnList;
      });

      // then prefferedName and idCC
    }
  }, []);

  /*
    Handle options for the reacstrap input fields
  */

  switch (specificationAttributeData.irdiPR.dataType) {
    case 'INTEGER_COUNT':
    case 'INTEGER_MEASURE':
    case 'REAL_MEASURE':
    case 'REAL_COUNT':
      inputAttributes.type = 'number';
      break;
    case 'DATE':
      inputAttributes.type = 'date';
      break;
    case 'BOOLEAN':
    case 'CLASSIFICATION_LIST':  
      inputAttributes.type = 'select';
      break;
    case 'STRING':
    case 'STRING_TRANSLATABLE':
    default:
      inputAttributes.type = 'text';
  }
  if (specificationAttributeData?.irdiPR.possibleValues) {
    inputAttributes.type = 'select';
  }
  if (specificationAttributeData.irdiPR.numberOfCharacters) {
    inputAttributes['maxLength'] = specificationAttributeData?.irdiPR.numberOfCharacters;
  }

  return (
    <React.Fragment>
      <tr key={specificationAttributeData?.id}>
        <td>
          <FormGroup row>
            <Label sm={4} for="prefferedName">
              {specificationAttributeData?.irdiPR.prefferedName} <br />
              <span id={specificationAttributeData?.irdiPR.idPR + 'id'}>
                <FontAwesomeIcon icon="info-circle" size="lg" />
              </span>
              &nbsp;
              <span onClick={handleCopyClick}>
                <FontAwesomeIcon icon="copy" size="lg" />
                {isCopied ? ' kopiert!' : ''}
              </span>
              <Tooltip placement="right" isOpen={tooltipOpen} target={specificationAttributeData?.irdiPR.idPR + 'id'} toggle={toggle}>
                {specificationAttributeData?.irdiPR.irdiPR}
              </Tooltip>
              <p>
                {/*
                  Comment:
                                 {specificationAttributeData?.irdiPR.attributeType}:{specificationAttributeData?.irdiPR.dataType}:
                {specificationAttributeData?.irdiPR.numberOfCharacters} -{specificationAttributeData?.irdiPR.category}
 
                */}
              </p>
            </Label>
            <Col sm={7}>
              {specificationAttributeData?.irdiPR.dataType === 'CLASSIFICATION_LIST' ? (
                <Input {...inputAttributes} readOnly={isReadOnly} onChange={node => (specificationAttributeData.data = node.target.value)} value={specificationAttributeData.data}>
                  <option key="" value=""></option>
                  {classificationList
                    .sort((a, b) => a.prefferedName.toLowerCase().localeCompare(b.prefferedName.toLowerCase()))
                    .map(value => (
                      <option key={value.irdiCC} value={value.irdiCC}>
                        {value.prefferedName}
                      </option>
                    ))}
                </Input>
              ) : (
                <>
                  {specificationAttributeData?.irdiPR.possibleValues ? (
                    <Input
                      {...inputAttributes}
                      readOnly={isReadOnly}
                      onChange={node => (specificationAttributeData.data = node.target.value)}
                    >
                      <option key="" value=""></option>
                      {specificationAttributeData.irdiPR.possibleValues
                        .sort((a, b) => a.prefferedName.toLowerCase().localeCompare(b.prefferedName.toLowerCase()))
                        .map(value => (
                          <option key={value.id} value={value.prefferedName}>
                            {value.prefferedName}
                          </option>
                        ))}
                    </Input>
                  ) : (
                    <div>
                      {specificationAttributeData.irdiPR.dataType === 'BOOLEAN' ? (
                        <Input
                          {...inputAttributes}
                          readOnly={isReadOnly}
                          onChange={node => (specificationAttributeData.data = node.target.value)}
                          className="w-auto"
                        >
                          <option key="" value=""></option>
                          <option key="entfernen" value="">
                            (entfernen)
                          </option>
                          <option key="true" value="true">
                            Ja
                          </option>
                          <option key="false" value="false">
                            Nein
                          </option>
                        </Input>
                      ) : (
                        <Input
                          {...inputAttributes}
                          readOnly={isReadOnly}
                          onChange={node => (specificationAttributeData.data = node.target.value)}
                        />
                      )}
                    </div>
                  )}
                </>
              )}

              <FormText color="muted">{specificationAttributeData?.irdiPR.definition}</FormText>
            </Col>
            <Col className="EclassSpecificationAttributeItemUnit" sm={1}>
              {specificationAttributeData?.irdiPR?.unit?.shortName}
            </Col>
          </FormGroup>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default EclassSpecificationAttributeItem;
