import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './keyword.reducer';
import { IKeyword } from 'app/shared/model/keyword.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IKeywordUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const KeywordUpdate = (props: IKeywordUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { keywordEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/keyword' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...keywordEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.keyword.home.createOrEditLabel">
            <Translate contentKey="eclassApp.keyword.home.createOrEditLabel">Create or edit a Keyword</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : keywordEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="keyword-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="keyword-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="supplierKWLabel" for="keyword-supplierKW">
                  <Translate contentKey="eclassApp.keyword.supplierKW">Supplier KW</Translate>
                </Label>
                <AvField
                  id="keyword-supplierKW"
                  type="text"
                  name="supplierKW"
                  validate={{
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="supplierKWLabel">
                  <Translate contentKey="eclassApp.keyword.help.supplierKW" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="identifierLabel" for="keyword-identifier">
                  <Translate contentKey="eclassApp.keyword.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="keyword-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="identifierLabel">
                  <Translate contentKey="eclassApp.keyword.help.identifier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="keyword-versionNumber">
                  <Translate contentKey="eclassApp.keyword.versionNumber">Version Number</Translate>
                </Label>
                <AvField id="keyword-versionNumber" type="string" className="form-control" name="versionNumber" />
                <UncontrolledTooltip target="versionNumberLabel">
                  <Translate contentKey="eclassApp.keyword.help.versionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="idCCLabel" for="keyword-idCC">
                  <Translate contentKey="eclassApp.keyword.idCC">Id CC</Translate>
                </Label>
                <AvField
                  id="keyword-idCC"
                  type="text"
                  name="idCC"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 9, errorMessage: translate('entity.validation.maxlength', { max: 9 }) },
                  }}
                />
                <UncontrolledTooltip target="idCCLabel">
                  <Translate contentKey="eclassApp.keyword.help.idCC" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="valueLabel" for="keyword-value">
                  <Translate contentKey="eclassApp.keyword.value">Value</Translate>
                </Label>
                <AvField
                  id="keyword-value"
                  type="text"
                  name="value"
                  validate={{
                    maxLength: { value: 80, errorMessage: translate('entity.validation.maxlength', { max: 80 }) },
                  }}
                />
                <UncontrolledTooltip target="valueLabel">
                  <Translate contentKey="eclassApp.keyword.help.value" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="explanationLabel" for="keyword-explanation">
                  <Translate contentKey="eclassApp.keyword.explanation">Explanation</Translate>
                </Label>
                <AvField
                  id="keyword-explanation"
                  type="text"
                  name="explanation"
                  validate={{
                    maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) },
                  }}
                />
                <UncontrolledTooltip target="explanationLabel">
                  <Translate contentKey="eclassApp.keyword.help.explanation" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOLanguageCodeLabel" for="keyword-iSOLanguageCode">
                  <Translate contentKey="eclassApp.keyword.iSOLanguageCode">I SO Language Code</Translate>
                </Label>
                <AvField
                  id="keyword-iSOLanguageCode"
                  type="text"
                  name="iSOLanguageCode"
                  validate={{
                    maxLength: { value: 2, errorMessage: translate('entity.validation.maxlength', { max: 2 }) },
                  }}
                />
                <UncontrolledTooltip target="iSOLanguageCodeLabel">
                  <Translate contentKey="eclassApp.keyword.help.iSOLanguageCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOCountryCodeLabel" for="keyword-iSOCountryCode">
                  <Translate contentKey="eclassApp.keyword.iSOCountryCode">I SO Country Code</Translate>
                </Label>
                <AvInput
                  id="keyword-iSOCountryCode"
                  type="select"
                  className="form-control"
                  name="iSOCountryCode"
                  value={(!isNew && keywordEntity.iSOCountryCode) || 'DE'}
                >
                  <option value="DE">{translate('eclassApp.ISOCountryCode.DE')}</option>
                  <option value="US">{translate('eclassApp.ISOCountryCode.US')}</option>
                </AvInput>
                <UncontrolledTooltip target="iSOCountryCodeLabel">
                  <Translate contentKey="eclassApp.keyword.help.iSOCountryCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="typeOfTargetSELabel" for="keyword-typeOfTargetSE">
                  <Translate contentKey="eclassApp.keyword.typeOfTargetSE">Type Of Target SE</Translate>
                </Label>
                <AvInput
                  id="keyword-typeOfTargetSE"
                  type="select"
                  className="form-control"
                  name="typeOfTargetSE"
                  value={(!isNew && keywordEntity.typeOfTargetSE) || 'CC'}
                >
                  <option value="CC">{translate('eclassApp.EclassTypeOfTargetSE.CC')}</option>
                  <option value="PR">{translate('eclassApp.EclassTypeOfTargetSE.PR')}</option>
                </AvInput>
                <UncontrolledTooltip target="typeOfTargetSELabel">
                  <Translate contentKey="eclassApp.keyword.help.typeOfTargetSE" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiTargetLabel" for="keyword-irdiTarget">
                  <Translate contentKey="eclassApp.keyword.irdiTarget">Irdi Target</Translate>
                </Label>
                <AvField
                  id="keyword-irdiTarget"
                  type="text"
                  name="irdiTarget"
                  validate={{
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiTargetLabel">
                  <Translate contentKey="eclassApp.keyword.help.irdiTarget" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiKWLabel" for="keyword-irdiKW">
                  <Translate contentKey="eclassApp.keyword.irdiKW">Irdi KW</Translate>
                </Label>
                <AvField
                  id="keyword-irdiKW"
                  type="text"
                  name="irdiKW"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiKWLabel">
                  <Translate contentKey="eclassApp.keyword.help.irdiKW" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="typeOfSELabel" for="keyword-typeOfSE">
                  <Translate contentKey="eclassApp.keyword.typeOfSE">Type Of SE</Translate>
                </Label>
                <AvInput
                  id="keyword-typeOfSE"
                  type="select"
                  className="form-control"
                  name="typeOfSE"
                  value={(!isNew && keywordEntity.typeOfSE) || 'KW'}
                >
                  <option value="KW">{translate('eclassApp.EclassTypeOfSE.KW')}</option>
                  <option value="SY">{translate('eclassApp.EclassTypeOfSE.SY')}</option>
                </AvInput>
                <UncontrolledTooltip target="typeOfSELabel">
                  <Translate contentKey="eclassApp.keyword.help.typeOfSE" />
                </UncontrolledTooltip>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/keyword" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  keywordEntity: storeState.keyword.entity,
  loading: storeState.keyword.loading,
  updating: storeState.keyword.updating,
  updateSuccess: storeState.keyword.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KeywordUpdate);
