import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './specification.reducer';
import { ISpecification } from 'app/shared/model/specification.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface ISpecificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SpecificationDetail = (props: ISpecificationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { specificationEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.specification.detail.title">Specification</Translate> [<b>{specificationEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="idSD">
              <Translate contentKey="eclassApp.specification.idSD">Id SS</Translate>
            </span>
            <UncontrolledTooltip target="idSD">
              <Translate contentKey="eclassApp.specification.help.idSD" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.idSD}</dd>
          <dt>
            <span id="irdiSD">
              <Translate contentKey="eclassApp.specification.irdiSD">Irdi SD</Translate>
            </span>
            <UncontrolledTooltip target="irdiSD">
              <Translate contentKey="eclassApp.specification.help.irdiSD" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.irdiSD}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="eclassApp.specification.identifier">Identifier</Translate>
            </span>
            <UncontrolledTooltip target="identifier">
              <Translate contentKey="eclassApp.specification.help.identifier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.identifier}</dd>
          <dt>
            <span id="supplier">
              <Translate contentKey="eclassApp.specification.supplier">Supplier</Translate>
            </span>
            <UncontrolledTooltip target="supplier">
              <Translate contentKey="eclassApp.specification.help.supplier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.supplier}</dd>
          <dt>
            <span id="prefferedName">
              <Translate contentKey="eclassApp.specification.prefferedName">Preffered Name</Translate>
            </span>
            <UncontrolledTooltip target="prefferedName">
              <Translate contentKey="eclassApp.specification.help.prefferedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.prefferedName}</dd>
          <dt>
            <span id="definition">
              <Translate contentKey="eclassApp.specification.definition">Definition</Translate>
            </span>
            <UncontrolledTooltip target="definition">
              <Translate contentKey="eclassApp.specification.help.definition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.definition}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="eclassApp.specification.note">Note</Translate>
            </span>
            <UncontrolledTooltip target="note">
              <Translate contentKey="eclassApp.specification.help.note" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.note}</dd>
          <dt>
            <span id="versionNumber">
              <Translate contentKey="eclassApp.specification.versionNumber">Version Number</Translate>
            </span>
            <UncontrolledTooltip target="versionNumber">
              <Translate contentKey="eclassApp.specification.help.versionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.versionNumber}</dd>
          <dt>
            <span id="versionDate">
              <Translate contentKey="eclassApp.specification.versionDate">Version Date</Translate>
            </span>
            <UncontrolledTooltip target="versionDate">
              <Translate contentKey="eclassApp.specification.help.versionDate" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {specificationEntity.versionDate ? (
              <TextFormat value={specificationEntity.versionDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="revisionNumber">
              <Translate contentKey="eclassApp.specification.revisionNumber">Revision Number</Translate>
            </span>
            <UncontrolledTooltip target="revisionNumber">
              <Translate contentKey="eclassApp.specification.help.revisionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{specificationEntity.revisionNumber}</dd>
          <dt>
            <Translate contentKey="eclassApp.specification.irdiCC">Irdi CC</Translate>
          </dt>
          <dd>{specificationEntity.irdiCC ? specificationEntity.irdiCC.irdiCC : ''}</dd>
          <dt>
            <Translate contentKey="eclassApp.specification.user">User</Translate>
          </dt>
          <dd>{specificationEntity.user ? specificationEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/specification" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/specification/${specificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ specification }: IRootState) => ({
  specificationEntity: specification.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SpecificationDetail);
