import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './eclass-value.reducer';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IEclassValueDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EclassValueDetail = (props: IEclassValueDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { eclassValueEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.eclassValue.detail.title">EclassValue</Translate> [<b>{eclassValueEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="supplier">
              <Translate contentKey="eclassApp.eclassValue.supplier">Supplier</Translate>
            </span>
            <UncontrolledTooltip target="supplier">
              <Translate contentKey="eclassApp.eclassValue.help.supplier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.supplier}</dd>
          <dt>
            <span id="idVA">
              <Translate contentKey="eclassApp.eclassValue.idVA">Id VA</Translate>
            </span>
            <UncontrolledTooltip target="idVA">
              <Translate contentKey="eclassApp.eclassValue.help.idVA" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.idVA}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="eclassApp.eclassValue.identifier">Identifier</Translate>
            </span>
            <UncontrolledTooltip target="identifier">
              <Translate contentKey="eclassApp.eclassValue.help.identifier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.identifier}</dd>
          <dt>
            <span id="versionNumber">
              <Translate contentKey="eclassApp.eclassValue.versionNumber">Version Number</Translate>
            </span>
            <UncontrolledTooltip target="versionNumber">
              <Translate contentKey="eclassApp.eclassValue.help.versionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.versionNumber}</dd>
          <dt>
            <span id="revisionNumber">
              <Translate contentKey="eclassApp.eclassValue.revisionNumber">Revision Number</Translate>
            </span>
            <UncontrolledTooltip target="revisionNumber">
              <Translate contentKey="eclassApp.eclassValue.help.revisionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.revisionNumber}</dd>
          <dt>
            <span id="versionDate">
              <Translate contentKey="eclassApp.eclassValue.versionDate">Version Date</Translate>
            </span>
            <UncontrolledTooltip target="versionDate">
              <Translate contentKey="eclassApp.eclassValue.help.versionDate" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {eclassValueEntity.versionDate ? (
              <TextFormat value={eclassValueEntity.versionDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="prefferedName">
              <Translate contentKey="eclassApp.eclassValue.prefferedName">Preffered Name</Translate>
            </span>
            <UncontrolledTooltip target="prefferedName">
              <Translate contentKey="eclassApp.eclassValue.help.prefferedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.prefferedName}</dd>
          <dt>
            <span id="shortName">
              <Translate contentKey="eclassApp.eclassValue.shortName">Short Name</Translate>
            </span>
            <UncontrolledTooltip target="shortName">
              <Translate contentKey="eclassApp.eclassValue.help.shortName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.shortName}</dd>
          <dt>
            <span id="definition">
              <Translate contentKey="eclassApp.eclassValue.definition">Definition</Translate>
            </span>
            <UncontrolledTooltip target="definition">
              <Translate contentKey="eclassApp.eclassValue.help.definition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.definition}</dd>
          <dt>
            <span id="reference">
              <Translate contentKey="eclassApp.eclassValue.reference">Reference</Translate>
            </span>
            <UncontrolledTooltip target="reference">
              <Translate contentKey="eclassApp.eclassValue.help.reference" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.reference}</dd>
          <dt>
            <span id="iSOLanguageCode">
              <Translate contentKey="eclassApp.eclassValue.iSOLanguageCode">I SO Language Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOLanguageCode">
              <Translate contentKey="eclassApp.eclassValue.help.iSOLanguageCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.iSOLanguageCode}</dd>
          <dt>
            <span id="iSOCountryCode">
              <Translate contentKey="eclassApp.eclassValue.iSOCountryCode">I SO Country Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOCountryCode">
              <Translate contentKey="eclassApp.eclassValue.help.iSOCountryCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.iSOCountryCode}</dd>
          <dt>
            <span id="irdiVA">
              <Translate contentKey="eclassApp.eclassValue.irdiVA">Irdi VA</Translate>
            </span>
            <UncontrolledTooltip target="irdiVA">
              <Translate contentKey="eclassApp.eclassValue.help.irdiVA" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.irdiVA}</dd>
          <dt>
            <span id="dataType">
              <Translate contentKey="eclassApp.eclassValue.dataType">Data Type</Translate>
            </span>
            <UncontrolledTooltip target="dataType">
              <Translate contentKey="eclassApp.eclassValue.help.dataType" />
            </UncontrolledTooltip>
          </dt>
          <dd>{eclassValueEntity.dataType}</dd>
        </dl>
        <Button tag={Link} to="/eclass-value" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/eclass-value/${eclassValueEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ eclassValue }: IRootState) => ({
  eclassValueEntity: eclassValue.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EclassValueDetail);
