export interface IUnit {
  id?: number;
  structuredNaming?: string;
  shortName?: string;
  definition?: string;
  source?: string;
  comment?: string;
  sINotation?: string;
  sIName?: string;
  sINNotation?: string;
  eCEName?: string;
  eCECode?: string;
  nISTName?: string;
  iECClassification?: string;
  irdiUN?: string;
  nameOfDedicatedQuantity?: string;
}

export const defaultValue: Readonly<IUnit> = {};
