import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './attribute.reducer';
import { IAttribute } from 'app/shared/model/attribute.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IAttributeDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AttributeDetail = (props: IAttributeDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { attributeEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.attribute.detail.title">Attribute</Translate> [<b>{attributeEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="supplier">
              <Translate contentKey="eclassApp.attribute.supplier">Supplier</Translate>
            </span>
            <UncontrolledTooltip target="supplier">
              <Translate contentKey="eclassApp.attribute.help.supplier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.supplier}</dd>
          <dt>
            <span id="idPR">
              <Translate contentKey="eclassApp.attribute.idPR">Id PR</Translate>
            </span>
            <UncontrolledTooltip target="idPR">
              <Translate contentKey="eclassApp.attribute.help.idPR" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.idPR}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="eclassApp.attribute.identifier">Identifier</Translate>
            </span>
            <UncontrolledTooltip target="identifier">
              <Translate contentKey="eclassApp.attribute.help.identifier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.identifier}</dd>
          <dt>
            <span id="versionNumber">
              <Translate contentKey="eclassApp.attribute.versionNumber">Version Number</Translate>
            </span>
            <UncontrolledTooltip target="versionNumber">
              <Translate contentKey="eclassApp.attribute.help.versionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.versionNumber}</dd>
          <dt>
            <span id="versionDate">
              <Translate contentKey="eclassApp.attribute.versionDate">Version Date</Translate>
            </span>
            <UncontrolledTooltip target="versionDate">
              <Translate contentKey="eclassApp.attribute.help.versionDate" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {attributeEntity.versionDate ? (
              <TextFormat value={attributeEntity.versionDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="revisionNumber">
              <Translate contentKey="eclassApp.attribute.revisionNumber">Revision Number</Translate>
            </span>
            <UncontrolledTooltip target="revisionNumber">
              <Translate contentKey="eclassApp.attribute.help.revisionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.revisionNumber}</dd>
          <dt>
            <span id="prefferedName">
              <Translate contentKey="eclassApp.attribute.prefferedName">Preffered Name</Translate>
            </span>
            <UncontrolledTooltip target="prefferedName">
              <Translate contentKey="eclassApp.attribute.help.prefferedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.prefferedName}</dd>
          <dt>
            <span id="shortName">
              <Translate contentKey="eclassApp.attribute.shortName">Short Name</Translate>
            </span>
            <UncontrolledTooltip target="shortName">
              <Translate contentKey="eclassApp.attribute.help.shortName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.shortName}</dd>
          <dt>
            <span id="definition">
              <Translate contentKey="eclassApp.attribute.definition">Definition</Translate>
            </span>
            <UncontrolledTooltip target="definition">
              <Translate contentKey="eclassApp.attribute.help.definition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.definition}</dd>
          <dt>
            <span id="sourceOfDefinition">
              <Translate contentKey="eclassApp.attribute.sourceOfDefinition">Source Of Definition</Translate>
            </span>
            <UncontrolledTooltip target="sourceOfDefinition">
              <Translate contentKey="eclassApp.attribute.help.sourceOfDefinition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.sourceOfDefinition}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="eclassApp.attribute.note">Note</Translate>
            </span>
            <UncontrolledTooltip target="note">
              <Translate contentKey="eclassApp.attribute.help.note" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.note}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="eclassApp.attribute.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="eclassApp.attribute.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.remark}</dd>
          <dt>
            <span id="formularSymbol">
              <Translate contentKey="eclassApp.attribute.formularSymbol">Formular Symbol</Translate>
            </span>
            <UncontrolledTooltip target="formularSymbol">
              <Translate contentKey="eclassApp.attribute.help.formularSymbol" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.formularSymbol}</dd>
          <dt>
            <span id="irdiUN">
              <Translate contentKey="eclassApp.attribute.irdiUN">Irdi UN</Translate>
            </span>
            <UncontrolledTooltip target="irdiUN">
              <Translate contentKey="eclassApp.attribute.help.irdiUN" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.irdiUN}</dd>
          <dt>
            <span id="iSOLanguageCode">
              <Translate contentKey="eclassApp.attribute.iSOLanguageCode">I SO Language Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOLanguageCode">
              <Translate contentKey="eclassApp.attribute.help.iSOLanguageCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.iSOLanguageCode}</dd>
          <dt>
            <span id="iSOCountryCode">
              <Translate contentKey="eclassApp.attribute.iSOCountryCode">I SO Country Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOCountryCode">
              <Translate contentKey="eclassApp.attribute.help.iSOCountryCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.iSOCountryCode}</dd>
          <dt>
            <span id="category">
              <Translate contentKey="eclassApp.attribute.category">Category</Translate>
            </span>
            <UncontrolledTooltip target="category">
              <Translate contentKey="eclassApp.attribute.help.category" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.category}</dd>
          <dt>
            <span id="attributeType">
              <Translate contentKey="eclassApp.attribute.attributeType">Attribute Type</Translate>
            </span>
            <UncontrolledTooltip target="attributeType">
              <Translate contentKey="eclassApp.attribute.help.attributeType" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.attributeType}</dd>
          <dt>
            <span id="definitionClass">
              <Translate contentKey="eclassApp.attribute.definitionClass">Definition Class</Translate>
            </span>
            <UncontrolledTooltip target="definitionClass">
              <Translate contentKey="eclassApp.attribute.help.definitionClass" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.definitionClass}</dd>
          <dt>
            <span id="dataType">
              <Translate contentKey="eclassApp.attribute.dataType">Data Type</Translate>
            </span>
            <UncontrolledTooltip target="dataType">
              <Translate contentKey="eclassApp.attribute.help.dataType" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.dataType}</dd>
          <dt>
            <span id="digitsBeforeComma">
              <Translate contentKey="eclassApp.attribute.digitsBeforeComma">Digits Before Comma</Translate>
            </span>
            <UncontrolledTooltip target="digitsBeforeComma">
              <Translate contentKey="eclassApp.attribute.help.digitsBeforeComma" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.digitsBeforeComma}</dd>
          <dt>
            <span id="digitsAfterComma">
              <Translate contentKey="eclassApp.attribute.digitsAfterComma">Digits After Comma</Translate>
            </span>
            <UncontrolledTooltip target="digitsAfterComma">
              <Translate contentKey="eclassApp.attribute.help.digitsAfterComma" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.digitsAfterComma}</dd>
          <dt>
            <span id="numberOfCharacters">
              <Translate contentKey="eclassApp.attribute.numberOfCharacters">Number Of Characters</Translate>
            </span>
            <UncontrolledTooltip target="numberOfCharacters">
              <Translate contentKey="eclassApp.attribute.help.numberOfCharacters" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.numberOfCharacters}</dd>
          <dt>
            <span id="irdiPR">
              <Translate contentKey="eclassApp.attribute.irdiPR">Irdi PR</Translate>
            </span>
            <UncontrolledTooltip target="irdiPR">
              <Translate contentKey="eclassApp.attribute.help.irdiPR" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.irdiPR}</dd>
          <dt>
            <span id="currencyAlphaCode">
              <Translate contentKey="eclassApp.attribute.currencyAlphaCode">Currency Alpha Code</Translate>
            </span>
            <UncontrolledTooltip target="currencyAlphaCode">
              <Translate contentKey="eclassApp.attribute.help.currencyAlphaCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{attributeEntity.currencyAlphaCode}</dd>
        </dl>
        <Button tag={Link} to="/attribute" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/attribute/${attributeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ attribute }: IRootState) => ({
  attributeEntity: attribute.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AttributeDetail);
