import { IClassification } from 'app/shared/model/classification.model';
import { IAttribute } from 'app/shared/model/attribute.model';

export interface IClassificationAttributeXref {
  id?: number;
  supplierIdCC?: string;
  idCC?: string;
  classCodedName?: string;
  supplierIdPR?: string;
  idPR?: string;
  irdiCC?: IClassification;
  irdiPR?: IAttribute;
}

export const defaultValue: Readonly<IClassificationAttributeXref> = {};
