import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Col, Row, Table } from 'reactstrap';
import { Translate, ICrudGetAllAction, TextFormat, getSortState, IPaginationBaseState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities } from './eclass-value.reducer';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

export interface IEclassValueProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> {}

export const EclassValue = (props: IEclassValueProps) => {
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );

  const getAllEntities = () => {
    props.getEntities(paginationState.activePage - 1, paginationState.itemsPerPage, `${paginationState.sort},${paginationState.order}`);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(props.location.search);
    const page = params.get('page');
    const sort = params.get('sort');
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [props.location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const { eclassValueList, match, loading, totalItems } = props;
  return (
    <div>
      <h2 id="eclass-value-heading">
        <Translate contentKey="eclassApp.eclassValue.home.title">Eclass Values</Translate>
        <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp;
          <Translate contentKey="eclassApp.eclassValue.home.createLabel">Create new Eclass Value</Translate>
        </Link>
      </h2>
      <div className="table-responsive">
        {eclassValueList && eclassValueList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="global.field.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('supplier')}>
                  <Translate contentKey="eclassApp.eclassValue.supplier">Supplier</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('idVA')}>
                  <Translate contentKey="eclassApp.eclassValue.idVA">Id VA</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('identifier')}>
                  <Translate contentKey="eclassApp.eclassValue.identifier">Identifier</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('versionNumber')}>
                  <Translate contentKey="eclassApp.eclassValue.versionNumber">Version Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('revisionNumber')}>
                  <Translate contentKey="eclassApp.eclassValue.revisionNumber">Revision Number</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('versionDate')}>
                  <Translate contentKey="eclassApp.eclassValue.versionDate">Version Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('prefferedName')}>
                  <Translate contentKey="eclassApp.eclassValue.prefferedName">Preffered Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('shortName')}>
                  <Translate contentKey="eclassApp.eclassValue.shortName">Short Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('definition')}>
                  <Translate contentKey="eclassApp.eclassValue.definition">Definition</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('reference')}>
                  <Translate contentKey="eclassApp.eclassValue.reference">Reference</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('iSOLanguageCode')}>
                  <Translate contentKey="eclassApp.eclassValue.iSOLanguageCode">I SO Language Code</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('iSOCountryCode')}>
                  <Translate contentKey="eclassApp.eclassValue.iSOCountryCode">I SO Country Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('irdiVA')}>
                  <Translate contentKey="eclassApp.eclassValue.irdiVA">Irdi VA</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('dataType')}>
                  <Translate contentKey="eclassApp.eclassValue.dataType">Data Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {eclassValueList.map((eclassValue, i) => (
                <tr key={`entity-${i}`}>
                  <td>
                    <Button tag={Link} to={`${match.url}/${eclassValue.id}`} color="link" size="sm">
                      {eclassValue.id}
                    </Button>
                  </td>
                  <td>{eclassValue.supplier}</td>
                  <td>{eclassValue.idVA}</td>
                  <td>{eclassValue.identifier}</td>
                  <td>{eclassValue.versionNumber}</td>
                  <td>{eclassValue.revisionNumber}</td>
                  <td>
                    {eclassValue.versionDate ? (
                      <TextFormat type="date" value={eclassValue.versionDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{eclassValue.prefferedName}</td>
                  <td>{eclassValue.shortName}</td>
                  <td>{eclassValue.definition}</td>
                  <td>{eclassValue.reference}</td>
                  <td>{eclassValue.iSOLanguageCode}</td>
                  <td>
                    <Translate contentKey={`eclassApp.ISOCountryCode.${eclassValue.iSOCountryCode}`} />
                  </td>
                  <td>{eclassValue.irdiVA}</td>
                  <td>
                    <Translate contentKey={`eclassApp.EclassDataType.${eclassValue.dataType}`} />
                  </td>
                  <td className="text-right">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`${match.url}/${eclassValue.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${eclassValue.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`${match.url}/${eclassValue.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="eclassApp.eclassValue.home.notFound">No Eclass Values found</Translate>
            </div>
          )
        )}
      </div>
      {props.totalItems ? (
        <div className={eclassValueList && eclassValueList.length > 0 ? '' : 'd-none'}>
          <Row className="justify-content-center">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </Row>
          <Row className="justify-content-center">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={props.totalItems}
            />
          </Row>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

const mapStateToProps = ({ eclassValue }: IRootState) => ({
  eclassValueList: eclassValue.entities,
  loading: eclassValue.loading,
  totalItems: eclassValue.totalItems,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EclassValue);
