import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './unit.reducer';
import { IUnit } from 'app/shared/model/unit.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IUnitDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UnitDetail = (props: IUnitDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { unitEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.unit.detail.title">Unit</Translate> [<b>{unitEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="structuredNaming">
              <Translate contentKey="eclassApp.unit.structuredNaming">Structured Naming</Translate>
            </span>
            <UncontrolledTooltip target="structuredNaming">
              <Translate contentKey="eclassApp.unit.help.structuredNaming" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.structuredNaming}</dd>
          <dt>
            <span id="shortName">
              <Translate contentKey="eclassApp.unit.shortName">Short Name</Translate>
            </span>
            <UncontrolledTooltip target="shortName">
              <Translate contentKey="eclassApp.unit.help.shortName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.shortName}</dd>
          <dt>
            <span id="definition">
              <Translate contentKey="eclassApp.unit.definition">Definition</Translate>
            </span>
            <UncontrolledTooltip target="definition">
              <Translate contentKey="eclassApp.unit.help.definition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.definition}</dd>
          <dt>
            <span id="source">
              <Translate contentKey="eclassApp.unit.source">Source</Translate>
            </span>
            <UncontrolledTooltip target="source">
              <Translate contentKey="eclassApp.unit.help.source" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.source}</dd>
          <dt>
            <span id="comment">
              <Translate contentKey="eclassApp.unit.comment">Comment</Translate>
            </span>
            <UncontrolledTooltip target="comment">
              <Translate contentKey="eclassApp.unit.help.comment" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.comment}</dd>
          <dt>
            <span id="sINotation">
              <Translate contentKey="eclassApp.unit.sINotation">S I Notation</Translate>
            </span>
            <UncontrolledTooltip target="sINotation">
              <Translate contentKey="eclassApp.unit.help.sINotation" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.sINotation}</dd>
          <dt>
            <span id="sIName">
              <Translate contentKey="eclassApp.unit.sIName">S I Name</Translate>
            </span>
            <UncontrolledTooltip target="sIName">
              <Translate contentKey="eclassApp.unit.help.sIName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.sIName}</dd>
          <dt>
            <span id="sINNotation">
              <Translate contentKey="eclassApp.unit.sINNotation">S IN Notation</Translate>
            </span>
            <UncontrolledTooltip target="sINNotation">
              <Translate contentKey="eclassApp.unit.help.sINNotation" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.sINNotation}</dd>
          <dt>
            <span id="eCEName">
              <Translate contentKey="eclassApp.unit.eCEName">E CE Name</Translate>
            </span>
            <UncontrolledTooltip target="eCEName">
              <Translate contentKey="eclassApp.unit.help.eCEName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.eCEName}</dd>
          <dt>
            <span id="eCECode">
              <Translate contentKey="eclassApp.unit.eCECode">E CE Code</Translate>
            </span>
            <UncontrolledTooltip target="eCECode">
              <Translate contentKey="eclassApp.unit.help.eCECode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.eCECode}</dd>
          <dt>
            <span id="nISTName">
              <Translate contentKey="eclassApp.unit.nISTName">N IST Name</Translate>
            </span>
            <UncontrolledTooltip target="nISTName">
              <Translate contentKey="eclassApp.unit.help.nISTName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.nISTName}</dd>
          <dt>
            <span id="iECClassification">
              <Translate contentKey="eclassApp.unit.iECClassification">I EC Classification</Translate>
            </span>
            <UncontrolledTooltip target="iECClassification">
              <Translate contentKey="eclassApp.unit.help.iECClassification" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.iECClassification}</dd>
          <dt>
            <span id="irdiUN">
              <Translate contentKey="eclassApp.unit.irdiUN">Irdi UN</Translate>
            </span>
            <UncontrolledTooltip target="irdiUN">
              <Translate contentKey="eclassApp.unit.help.irdiUN" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.irdiUN}</dd>
          <dt>
            <span id="nameOfDedicatedQuantity">
              <Translate contentKey="eclassApp.unit.nameOfDedicatedQuantity">Name Of Dedicated Quantity</Translate>
            </span>
            <UncontrolledTooltip target="nameOfDedicatedQuantity">
              <Translate contentKey="eclassApp.unit.help.nameOfDedicatedQuantity" />
            </UncontrolledTooltip>
          </dt>
          <dd>{unitEntity.nameOfDedicatedQuantity}</dd>
        </dl>
        <Button tag={Link} to="/unit" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/unit/${unitEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ unit }: IRootState) => ({
  unitEntity: unit.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UnitDetail);
