import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Button, CardBody, Table, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntities as getClassifications } from 'app/entities/classification/classification.reducer';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, deleteEntity, reset } from 'app/entities/specification/specification.reducer';
import { ISpecification } from 'app/shared/model/specification.model';

import EclassAttributeSearchBox from './eclass-attribute-search-box';
import EclassSpecificationSearch from './eclass-specification-search';
import EclassSpecificationAttributeItem from './eclass-specification-attribute-item';
import { useEclassState } from './eclass-state-provider';

import './eclass-datasheet.scss';
import { ISpecificationAttributeData } from 'app/shared/model/specification-attribute-data.model';

export interface ISpecificationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  datasheetList;
  selectedClassification;
}

export const EclassDatasheet = (props: ISpecificationUpdateProps) => {
  const [isNew, setIsNew] = useState(true);
  const [selectedSpecification, setSelectedSpecification] = useState(null);
  const [resetSpecificationSearch, setResetSpecificationSearch] = useState(null);
  const [dirtySpecificationForm, setDirtySpecificationForm] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { setState } = useEclassState();
  const { state } = useEclassState();
  const finalizeCheckbox = createRef<AvInput<{}, {}, any>>();

  const { specificationEntity, classifications, users, loading, updating, datasheetList, selectedClassification } = props;

  // const { datasheetList, selectedClassification } = props;
  const [workingDatasheetList, setWorkingDatasheetList] = useState(datasheetList);

  React.useEffect(() => {
    setWorkingDatasheetList(datasheetList);
    if (selectedSpecification?.id > 0) {
      setIsNew(false);
    } else {
      setIsNew(true);
    }
  }, [datasheetList]);

  // specificationAttributeDataList
  function addAllAttributes(attributes) {
    const data = {
      attributeListToAdd: [],
    };
    if (state.attributeListToAdd) {
      data.attributeListToAdd = state.attributeListToAdd;
    }
    // loop through all!!!
    attributes.forEach(attribute => {
      data.attributeListToAdd.push(attribute);
    });
    // and update the state with the list to add
    setState(prev => ({ ...prev, ...data }));
  }

  React.useEffect(() => {
    let shouldReRender = false;
    if (state.attributeListToAdd != null) {
      // eslint-disable-next-line no-console
      console.log('eclass-datasheet: attributeListToAdd', state.attributeListToAdd);
      if (state.attributeListToAdd.length === 1) {
        shouldReRender = true;
      }
      let wipDatasheetList = workingDatasheetList;
      state.attributeListToAdd.forEach(attribute => {
        // if it is empty/undefined, then initialize the array.
        if (!wipDatasheetList) {
          wipDatasheetList = [];
        }
        // only add if the attribute is not already in the list
        if (
          !wipDatasheetList.find(a => {
            return a.irdiPR.id === attribute.id;
          })
        ) {
          const newSpecificationAttributeData: ISpecificationAttributeData = {
            irdiSD: JSON.parse(JSON.stringify(selectedSpecification)),
            irdiPR: attribute,
          };
          if (newSpecificationAttributeData.irdiSD.specificationAttributeData) {
            newSpecificationAttributeData.irdiSD.specificationAttributeData.length = 0;
          }
          wipDatasheetList.push(newSpecificationAttributeData);
        }
      });
      delete state.attributeListToAdd;
      setWorkingDatasheetList(wipDatasheetList);
      setDirtySpecificationForm(true);
      if (shouldReRender) {
        const newState = Object.assign({}, state);
        newState.attributeListToAdd = [];
        setState(newState);
      }
    }
  }, [state.attributeListToAdd]);

  const formDirty = (event, errors, values) => {
    // eslint-disable-next-line no-console
    console.log('eclass-datasheet: FORM dirty', event);
    setDirtySpecificationForm(true);
  };

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      let entity: ISpecification = {};
      if (isNew) {
        entity = {
          prefferedName: 'neues Stammdatenblatt',
          irdiCC: selectedClassification,
          irdiSD: '',
          idSD: '',
        };
        entity = {
          ...entity,
          ...values,
        };
        entity.specificationAttributeData = [].concat(workingDatasheetList);
      } else {
        entity = {
          ...selectedSpecification,
          ...values,
        };
        entity.specificationAttributeData = [].concat(workingDatasheetList);
      }
      if (values.finalize && values.finalize === true) {
        const version = {
          versionNumber: -1,
        };
        entity = {
          ...entity,
          ...version,
        };
        if (finalizeCheckbox?.current?.value) {
          finalizeCheckbox.current.value = false;
        }
      }
      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
      setDirtySpecificationForm(false);
    }
  };

  React.useEffect(() => {
    if (props.updateSuccess) {
      if (isNew) {
        setIsNew(false);
        // eslint-disable-next-line no-console
        console.log('eclass-datasheet: created success - ', props.specificationEntity);
        setResetSpecificationSearch(props.specificationEntity.identifier);
        // figure out how to update the specification in the specificationList in the parent component! - Why?
      } else {
        // eslint-disable-next-line no-console
        console.log('eclass-datasheet: updated success - ', props.specificationEntity);
        setResetSpecificationSearch(props.specificationEntity.identifier);
        setSelectedSpecification(props.specificationEntity);
      }
      // eslint-disable-next-line no-console
      console.log('eclass-datasheet: updateSuccess - ', props.specificationEntity, props.specificationEntity.versionNumber, props.specificationEntity.revisionNumber);
      if (props.specificationEntity.versionNumber === 0) {
        // disable checkbox for finalize, and set to final
        setInProgress(false);
      }
    }
  }, [props.updateSuccess]);

  const deleteThisEntity = () => {
    props.deleteEntity(selectedSpecification.id);
    let previousRecord = 0;
    for (let i = 1; i < workingDatasheetList.length; i++) {
      if (workingDatasheetList[i].id === selectedSpecification.id) {
        previousRecord = workingDatasheetList[i - 1].id;
      }
    }
    // we want to reset to index - 1 in list!
    setResetSpecificationSearch(previousRecord);
  };

  const copyAndCreateNewSpecification = () => {
    setInProgress(true);
    selectedSpecification.versionNumber = 999;
    // eslint-disable-next-line no-console
    console.log('eclass-datasheet: copyAndCreateNewSpecification', selectedSpecification);
  };

  const excelExport = specificationId => {
    // onst newWindow = window.open('/excel', '_blank', 'noopener,noreferrer')
    const newWindow = window.open('/excel?specificationId=' + specificationId, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const restExport = specificationId => {
    // onst newWindow = window.open('/excel', '_blank', 'noopener,noreferrer')
    const newWindow = window.open('/api/specifications/' + specificationId, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  React.useEffect(() => {
    // SORT the list here
    const sortedList = selectedSpecification?.specificationAttributeData?.sort((a, b) =>
      // SORT with category
      a.irdiPR.prefferedName.toLowerCase().localeCompare(b.irdiPR.prefferedName.toLowerCase())
    );

    setWorkingDatasheetList(sortedList);
    if (selectedSpecification && selectedSpecification.id !== 0) {
      setIsNew(false);
    } else {
      setIsNew(true);
      // eslint-disable-next-line no-console
      console.log('eclass-datasheet: NEW Specification');

      // add the temp stuff
      if (selectedSpecification && selectedSpecification.id === 0) {
        setWorkingDatasheetList([]);
        addAllAttributes(state.tempAttributeListToAdd);
      }
    }
    if (selectedSpecification && selectedSpecification.versionNumber > 0) {
      setInProgress(true);
    } else {
      setInProgress(false);
    }
  }, [selectedSpecification]);

  React.useEffect(() => {
    const newState = Object.assign({}, state);
    newState.specificationIsDirty = dirtySpecificationForm;
    setState(newState);
  }, [dirtySpecificationForm]);

  React.useEffect(() => {
    const newState = Object.assign({}, state);
    newState.specificationInProgress = inProgress;
    setState(newState);
  }, [inProgress]);

  return (
    <React.Fragment>
      <EclassSpecificationSearch
        selectedClassification={selectedClassification}
        changeSelectedSpecification={setSelectedSpecification}
        resetSpecificationSearch={resetSpecificationSearch}
        setDirtySpecificationForm={setDirtySpecificationForm}
      ></EclassSpecificationSearch>
      <div>
        <AvForm id="datasheet" onSubmit={saveEntity} onChange={formDirty}>
          <CardBody>
            {workingDatasheetList?.length > 0 && inProgress ? (
              <>
                <Button id="sdSave" className="float-right" size="sm" color="success" disabled={!dirtySpecificationForm} value="save">
                  <FontAwesomeIcon icon="save" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.int-save">Save</Translate>
                  </span>
                </Button>
                &nbsp;
                <AvGroup className="float-right my-auto" check>
                  <AvInput type="checkbox" ref={finalizeCheckbox} bsSize="sm" name="finalize" />{' '}
                  <Label check for="checkbox">
                    {' '}
                    <Translate contentKey="entity.action.new-save">Finalize</Translate>
                  </Label>{' '}
                </AvGroup>
              </>
            ) : (
              <>
                <Button id="sdCopy" className="float-right" size="sm" color="success" onClick={() => copyAndCreateNewSpecification()}>
                  <FontAwesomeIcon icon="copy" />{' '}
                  <span className="d-none d-md-inline">
                    {isNew ? (
                      <>Speichern</>                      
                    ) : (
                      <Translate contentKey="entity.action.save-copy">Neue Stammdatenblatt Version darauss erstellen</Translate>
                    )}
                  </span>
                </Button>
              </>
            )}

            <Table striped>
              <thead>
                <tr>
                  <th>
                    {selectedSpecification?.irdiSD} {inProgress ? <span> - in bearbeitung</span> : <span></span>}
                    {workingDatasheetList?.length > 0 ? <small> : {workingDatasheetList?.length} Merkmale</small> : <span />}
                    {state.specificationInProgress ? (
                      <>
                        <span className="float-right EclassAttributeSearchBox">
                          <EclassAttributeSearchBox />
                        </span>
                      </>
                    ) : (
                      <></>
                    )}
                    <span className="float-right ml-1" onClick={q => excelExport(selectedSpecification?.id)}>
                      <FontAwesomeIcon icon="file-excel" size="lg" />
                    </span>
                    <span className="float-right" onClick={q => restExport(selectedSpecification?.id)}>
                      <FontAwesomeIcon icon="file-code" size="lg" />
                    </span>
                  </th>
                </tr>
              </thead>
              {workingDatasheetList?.length > 0 ? (
                <tbody>
                  {workingDatasheetList.map(specificationAttributeData => (
                    <EclassSpecificationAttributeItem
                      key={specificationAttributeData?.irdiPR?.id}
                      specificationAttributeData={specificationAttributeData}
                      selectedSpecification={selectedSpecification}
                      inProgress={inProgress}
                    ></EclassSpecificationAttributeItem>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td><></></td>
                  </tr>
                </tbody>
              )}
            </Table>
            {workingDatasheetList?.length > 0 && inProgress ? (
              <>
                <Button className="float-right" size="sm" color="success" disabled={!dirtySpecificationForm}>
                  <FontAwesomeIcon icon="save" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.int-save">Update</Translate>
                  </span>
                </Button>
                {selectedSpecification?.id > 0 ? (
                  <>
                    <Button color="danger" size="sm" onClick={deleteThisEntity}>
                      <FontAwesomeIcon icon="trash" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    </Button>
                  </>
                ) : (
                  ''
                )}
              </>
            ) : (
              ''
            )}
          </CardBody>
        </AvForm>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState, props) => ({
  classifications: storeState.classification.entities,
  users: storeState.userManagement.users,
  specificationEntity: storeState.specification.entity,
  loading: storeState.specification.loading,
  updating: storeState.specification.updating,
  selectedClassification: props.selectedClassification,
  datasheetList: props.datasheetList,
  updateSuccess: storeState.specification.updateSuccess,
});

const mapDispatchToProps = {
  getClassifications,
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  deleteEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EclassDatasheet);
