import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClassificationAttributeValueXref from './classification-attribute-value-xref';
import ClassificationAttributeValueXrefDetail from './classification-attribute-value-xref-detail';
import ClassificationAttributeValueXrefUpdate from './classification-attribute-value-xref-update';
import ClassificationAttributeValueXrefDeleteDialog from './classification-attribute-value-xref-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClassificationAttributeValueXrefUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClassificationAttributeValueXrefUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClassificationAttributeValueXrefDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClassificationAttributeValueXref} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ClassificationAttributeValueXrefDeleteDialog} />
  </>
);

export default Routes;
