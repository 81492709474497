import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Classification from './classification';
import Keyword from './keyword';
import Attribute from './attribute';
import EclassValue from './eclass-value';
import Unit from './unit';
import ClassificationAttributeXref from './classification-attribute-xref';
import ClassificationAttributeValueXref from './classification-attribute-value-xref';
import Specification from './specification';
import SpecificationAttributeData from './specification-attribute-data';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}classification`} component={Classification} />
      <ErrorBoundaryRoute path={`${match.url}keyword`} component={Keyword} />
      <ErrorBoundaryRoute path={`${match.url}attribute`} component={Attribute} />
      <ErrorBoundaryRoute path={`${match.url}eclass-value`} component={EclassValue} />
      <ErrorBoundaryRoute path={`${match.url}unit`} component={Unit} />
      <ErrorBoundaryRoute path={`${match.url}classification-attribute-xref`} component={ClassificationAttributeXref} />
      <ErrorBoundaryRoute path={`${match.url}classification-attribute-value-xref`} component={ClassificationAttributeValueXref} />
      <ErrorBoundaryRoute path={`${match.url}specification`} component={Specification} />
      <ErrorBoundaryRoute path={`${match.url}specification-attribute-data`} component={SpecificationAttributeData} />
      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
