import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './classification.reducer';
import { IClassification } from 'app/shared/model/classification.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IClassificationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClassificationUpdate = (props: IClassificationUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { classificationEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/classification' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...classificationEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.classification.home.createOrEditLabel">
            <Translate contentKey="eclassApp.classification.home.createOrEditLabel">Create or edit a Classification</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : classificationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="classification-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="classification-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="supplierLabel" for="classification-supplier">
                  <Translate contentKey="eclassApp.classification.supplier">Supplier</Translate>
                </Label>
                <AvField
                  id="classification-supplier"
                  type="text"
                  name="supplier"
                  validate={{
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="supplierLabel">
                  <Translate contentKey="eclassApp.classification.help.supplier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="idCCLabel" for="classification-idCC">
                  <Translate contentKey="eclassApp.classification.idCC">Id CC</Translate>
                </Label>
                <AvField
                  id="classification-idCC"
                  type="text"
                  name="idCC"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 9, errorMessage: translate('entity.validation.maxlength', { max: 9 }) },
                  }}
                />
                <UncontrolledTooltip target="idCCLabel">
                  <Translate contentKey="eclassApp.classification.help.idCC" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="identifierLabel" for="classification-identifier">
                  <Translate contentKey="eclassApp.classification.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="classification-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="identifierLabel">
                  <Translate contentKey="eclassApp.classification.help.identifier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="classification-versionNumber">
                  <Translate contentKey="eclassApp.classification.versionNumber">Version Number</Translate>
                </Label>
                <AvField id="classification-versionNumber" type="string" className="form-control" name="versionNumber" />
                <UncontrolledTooltip target="versionNumberLabel">
                  <Translate contentKey="eclassApp.classification.help.versionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionDateLabel" for="classification-versionDate">
                  <Translate contentKey="eclassApp.classification.versionDate">Version Date</Translate>
                </Label>
                <AvField id="classification-versionDate" type="date" className="form-control" name="versionDate" />
                <UncontrolledTooltip target="versionDateLabel">
                  <Translate contentKey="eclassApp.classification.help.versionDate" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="revisionNumberLabel" for="classification-revisionNumber">
                  <Translate contentKey="eclassApp.classification.revisionNumber">Revision Number</Translate>
                </Label>
                <AvField id="classification-revisionNumber" type="string" className="form-control" name="revisionNumber" />
                <UncontrolledTooltip target="revisionNumberLabel">
                  <Translate contentKey="eclassApp.classification.help.revisionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="codedNameLabel" for="classification-codedName">
                  <Translate contentKey="eclassApp.classification.codedName">Coded Name</Translate>
                </Label>
                <AvField
                  id="classification-codedName"
                  type="text"
                  name="codedName"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 8, errorMessage: translate('entity.validation.maxlength', { max: 8 }) },
                  }}
                />
                <UncontrolledTooltip target="codedNameLabel">
                  <Translate contentKey="eclassApp.classification.help.codedName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="prefferedNameLabel" for="classification-prefferedName">
                  <Translate contentKey="eclassApp.classification.prefferedName">Preffered Name</Translate>
                </Label>
                <AvField
                  id="classification-prefferedName"
                  type="text"
                  name="prefferedName"
                  validate={{
                    maxLength: { value: 512, errorMessage: translate('entity.validation.maxlength', { max: 512 }) },
                  }}
                />
                <UncontrolledTooltip target="prefferedNameLabel">
                  <Translate contentKey="eclassApp.classification.help.prefferedName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionLabel" for="classification-definition">
                  <Translate contentKey="eclassApp.classification.definition">Definition</Translate>
                </Label>
                <AvField
                  id="classification-definition"
                  type="text"
                  name="definition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionLabel">
                  <Translate contentKey="eclassApp.classification.help.definition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOLanguageCodeLabel" for="classification-iSOLanguageCode">
                  <Translate contentKey="eclassApp.classification.iSOLanguageCode">I SO Language Code</Translate>
                </Label>
                <AvField
                  id="classification-iSOLanguageCode"
                  type="text"
                  name="iSOLanguageCode"
                  validate={{
                    maxLength: { value: 2, errorMessage: translate('entity.validation.maxlength', { max: 2 }) },
                  }}
                />
                <UncontrolledTooltip target="iSOLanguageCodeLabel">
                  <Translate contentKey="eclassApp.classification.help.iSOLanguageCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOCountryCodeLabel" for="classification-iSOCountryCode">
                  <Translate contentKey="eclassApp.classification.iSOCountryCode">I SO Country Code</Translate>
                </Label>
                <AvInput
                  id="classification-iSOCountryCode"
                  type="select"
                  className="form-control"
                  name="iSOCountryCode"
                  value={(!isNew && classificationEntity.iSOCountryCode) || 'DE'}
                >
                  <option value="DE">{translate('eclassApp.ISOCountryCode.DE')}</option>
                  <option value="US">{translate('eclassApp.ISOCountryCode.US')}</option>
                </AvInput>
                <UncontrolledTooltip target="iSOCountryCodeLabel">
                  <Translate contentKey="eclassApp.classification.help.iSOCountryCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="noteLabel" for="classification-note">
                  <Translate contentKey="eclassApp.classification.note">Note</Translate>
                </Label>
                <AvField
                  id="classification-note"
                  type="text"
                  name="note"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="noteLabel">
                  <Translate contentKey="eclassApp.classification.help.note" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="remarkLabel" for="classification-remark">
                  <Translate contentKey="eclassApp.classification.remark">Remark</Translate>
                </Label>
                <AvField
                  id="classification-remark"
                  type="text"
                  name="remark"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="remarkLabel">
                  <Translate contentKey="eclassApp.classification.help.remark" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="levelLabel" for="classification-level">
                  <Translate contentKey="eclassApp.classification.level">Level</Translate>
                </Label>
                <AvField
                  id="classification-level"
                  type="string"
                  className="form-control"
                  name="level"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    number: { value: true, errorMessage: translate('entity.validation.number') },
                  }}
                />
                <UncontrolledTooltip target="levelLabel">
                  <Translate contentKey="eclassApp.classification.help.level" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup check>
                <Label id="mKSubclassLabel">
                  <AvInput id="classification-mKSubclass" type="checkbox" className="form-check-input" name="mKSubclass" />
                  <Translate contentKey="eclassApp.classification.mKSubclass">M K Subclass</Translate>
                </Label>
                <UncontrolledTooltip target="mKSubclassLabel">
                  <Translate contentKey="eclassApp.classification.help.mKSubclass" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup check>
                <Label id="mKKeywordLabel">
                  <AvInput id="classification-mKKeyword" type="checkbox" className="form-check-input" name="mKKeyword" />
                  <Translate contentKey="eclassApp.classification.mKKeyword">M K Keyword</Translate>
                </Label>
                <UncontrolledTooltip target="mKKeywordLabel">
                  <Translate contentKey="eclassApp.classification.help.mKKeyword" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="mKBSALabel" for="classification-mKBSA">
                  <Translate contentKey="eclassApp.classification.mKBSA">M KBSA</Translate>
                </Label>
                <AvField id="classification-mKBSA" type="string" className="form-control" name="mKBSA" />
                <UncontrolledTooltip target="mKBSALabel">
                  <Translate contentKey="eclassApp.classification.help.mKBSA" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiCCLabel" for="classification-irdiCC">
                  <Translate contentKey="eclassApp.classification.irdiCC">Irdi CC</Translate>
                </Label>
                <AvField
                  id="classification-irdiCC"
                  type="text"
                  name="irdiCC"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiCCLabel">
                  <Translate contentKey="eclassApp.classification.help.irdiCC" />
                </UncontrolledTooltip>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/classification" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  classificationEntity: storeState.classification.entity,
  loading: storeState.classification.loading,
  updating: storeState.classification.updating,
  updateSuccess: storeState.classification.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationUpdate);
