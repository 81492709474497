import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { IClassification } from 'app/shared/model/classification.model';
import { getEntities as getClassifications } from 'app/entities/classification/classification.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './specification.reducer';
import { ISpecification } from 'app/shared/model/specification.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface ISpecificationUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const SpecificationUpdate = (props: ISpecificationUpdateProps) => {
  const [irdiCCId, setIrdiCcId] = useState('0');
  const [userId, setUserId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { specificationEntity, classifications, users, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/specification' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getClassifications();
    props.getUsers();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...specificationEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.specification.home.createOrEditLabel">
            <Translate contentKey="eclassApp.specification.home.createOrEditLabel">Create or edit a Specification</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : specificationEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="specification-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="specification-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="idSDLabel" for="specification-idSD">
                  <Translate contentKey="eclassApp.specification.idSD">Id SS</Translate>
                </Label>
                <AvField
                  id="specification-idSD"
                  type="text"
                  name="idSD"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 9, errorMessage: translate('entity.validation.maxlength', { max: 9 }) },
                  }}
                />
                <UncontrolledTooltip target="idSDLabel">
                  <Translate contentKey="eclassApp.specification.help.idSD" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiSDLabel" for="specification-irdiSD">
                  <Translate contentKey="eclassApp.specification.irdiSD">Irdi SD</Translate>
                </Label>
                <AvField
                  id="specification-irdiSD"
                  type="text"
                  name="irdiSD"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiSDLabel">
                  <Translate contentKey="eclassApp.specification.help.irdiSD" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="identifierLabel" for="specification-identifier">
                  <Translate contentKey="eclassApp.specification.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="specification-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="identifierLabel">
                  <Translate contentKey="eclassApp.specification.help.identifier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="supplierLabel" for="specification-supplier">
                  <Translate contentKey="eclassApp.specification.supplier">Supplier</Translate>
                </Label>
                <AvField
                  id="specification-supplier"
                  type="text"
                  name="supplier"
                  validate={{
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="supplierLabel">
                  <Translate contentKey="eclassApp.specification.help.supplier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="prefferedNameLabel" for="specification-prefferedName">
                  <Translate contentKey="eclassApp.specification.prefferedName">Preffered Name</Translate>
                </Label>
                <AvField
                  id="specification-prefferedName"
                  type="text"
                  name="prefferedName"
                  validate={{
                    maxLength: { value: 80, errorMessage: translate('entity.validation.maxlength', { max: 80 }) },
                  }}
                />
                <UncontrolledTooltip target="prefferedNameLabel">
                  <Translate contentKey="eclassApp.specification.help.prefferedName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionLabel" for="specification-definition">
                  <Translate contentKey="eclassApp.specification.definition">Definition</Translate>
                </Label>
                <AvField
                  id="specification-definition"
                  type="text"
                  name="definition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionLabel">
                  <Translate contentKey="eclassApp.specification.help.definition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="noteLabel" for="specification-note">
                  <Translate contentKey="eclassApp.specification.note">Note</Translate>
                </Label>
                <AvField
                  id="specification-note"
                  type="text"
                  name="note"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="noteLabel">
                  <Translate contentKey="eclassApp.specification.help.note" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="specification-versionNumber">
                  <Translate contentKey="eclassApp.specification.versionNumber">Version Number</Translate>
                </Label>
                <AvField id="specification-versionNumber" type="string" className="form-control" name="versionNumber" />
                <UncontrolledTooltip target="versionNumberLabel">
                  <Translate contentKey="eclassApp.specification.help.versionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionDateLabel" for="specification-versionDate">
                  <Translate contentKey="eclassApp.specification.versionDate">Version Date</Translate>
                </Label>
                <AvField id="specification-versionDate" type="date" className="form-control" name="versionDate" />
                <UncontrolledTooltip target="versionDateLabel">
                  <Translate contentKey="eclassApp.specification.help.versionDate" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="revisionNumberLabel" for="specification-revisionNumber">
                  <Translate contentKey="eclassApp.specification.revisionNumber">Revision Number</Translate>
                </Label>
                <AvField id="specification-revisionNumber" type="string" className="form-control" name="revisionNumber" />
                <UncontrolledTooltip target="revisionNumberLabel">
                  <Translate contentKey="eclassApp.specification.help.revisionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label for="specification-irdiCC">
                  <Translate contentKey="eclassApp.specification.irdiCC">Irdi CC</Translate>
                </Label>
                <AvInput id="specification-irdiCC" type="select" className="form-control" name="irdiCC.id">
                  <option value="" key="0" />
                  {classifications
                    ? classifications.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.irdiCC}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <AvGroup>
                <Label for="specification-user">
                  <Translate contentKey="eclassApp.specification.user">User</Translate>
                </Label>
                <AvInput id="specification-user" type="select" className="form-control" name="user.id">
                  <option value="" key="0" />
                  {users
                    ? users.map(otherEntity => (
                        <option value={otherEntity.id} key={otherEntity.id}>
                          {otherEntity.id}
                        </option>
                      ))
                    : null}
                </AvInput>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/specification" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  classifications: storeState.classification.entities,
  users: storeState.userManagement.users,
  specificationEntity: storeState.specification.entity,
  loading: storeState.specification.loading,
  updating: storeState.specification.updating,
  updateSuccess: storeState.specification.updateSuccess,
});

const mapDispatchToProps = {
  getClassifications,
  getUsers,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SpecificationUpdate);
