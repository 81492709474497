import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EclassCancelChangeModal = props => {
  const { modal, toggle, cancel } = props;

  return (
    <Modal isOpen={modal} toggle={toggle} size="m" backdrop centered>
      <ModalHeader toggle={toggle}>Achtung</ModalHeader>

      <ModalBody>
        <Row>
          <Col sm="3">
            <FontAwesomeIcon size="6x" icon="exclamation-circle" />
          </Col>
          <Col sm="9">Veränderte Daten sind nicht gespeichert. Wollen Sie Ihre Änderungen speichern? Oder trotzdem fortfahren?</Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button form="datasheet" color="primary" type="submit" onClick={toggle}>
          Speichern
        </Button>{' '}
        <Button color="secondary" onClick={cancel}>
          Verwerfen
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EclassCancelChangeModal;
