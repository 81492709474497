import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './eclass-value.reducer';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IEclassValueUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const EclassValueUpdate = (props: IEclassValueUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { eclassValueEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/eclass-value' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...eclassValueEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.eclassValue.home.createOrEditLabel">
            <Translate contentKey="eclassApp.eclassValue.home.createOrEditLabel">Create or edit a EclassValue</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : eclassValueEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="eclass-value-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="eclass-value-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="supplierLabel" for="eclass-value-supplier">
                  <Translate contentKey="eclassApp.eclassValue.supplier">Supplier</Translate>
                </Label>
                <AvField
                  id="eclass-value-supplier"
                  type="text"
                  name="supplier"
                  validate={{
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="supplierLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.supplier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="idVALabel" for="eclass-value-idVA">
                  <Translate contentKey="eclassApp.eclassValue.idVA">Id VA</Translate>
                </Label>
                <AvField
                  id="eclass-value-idVA"
                  type="text"
                  name="idVA"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 9, errorMessage: translate('entity.validation.maxlength', { max: 9 }) },
                  }}
                />
                <UncontrolledTooltip target="idVALabel">
                  <Translate contentKey="eclassApp.eclassValue.help.idVA" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="identifierLabel" for="eclass-value-identifier">
                  <Translate contentKey="eclassApp.eclassValue.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="eclass-value-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="identifierLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.identifier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="eclass-value-versionNumber">
                  <Translate contentKey="eclassApp.eclassValue.versionNumber">Version Number</Translate>
                </Label>
                <AvField id="eclass-value-versionNumber" type="string" className="form-control" name="versionNumber" />
                <UncontrolledTooltip target="versionNumberLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.versionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="revisionNumberLabel" for="eclass-value-revisionNumber">
                  <Translate contentKey="eclassApp.eclassValue.revisionNumber">Revision Number</Translate>
                </Label>
                <AvField id="eclass-value-revisionNumber" type="string" className="form-control" name="revisionNumber" />
                <UncontrolledTooltip target="revisionNumberLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.revisionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionDateLabel" for="eclass-value-versionDate">
                  <Translate contentKey="eclassApp.eclassValue.versionDate">Version Date</Translate>
                </Label>
                <AvField id="eclass-value-versionDate" type="date" className="form-control" name="versionDate" />
                <UncontrolledTooltip target="versionDateLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.versionDate" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="prefferedNameLabel" for="eclass-value-prefferedName">
                  <Translate contentKey="eclassApp.eclassValue.prefferedName">Preffered Name</Translate>
                </Label>
                <AvField
                  id="eclass-value-prefferedName"
                  type="text"
                  name="prefferedName"
                  validate={{
                    maxLength: { value: 80, errorMessage: translate('entity.validation.maxlength', { max: 80 }) },
                  }}
                />
                <UncontrolledTooltip target="prefferedNameLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.prefferedName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="shortNameLabel" for="eclass-value-shortName">
                  <Translate contentKey="eclassApp.eclassValue.shortName">Short Name</Translate>
                </Label>
                <AvField
                  id="eclass-value-shortName"
                  type="text"
                  name="shortName"
                  validate={{
                    maxLength: { value: 17, errorMessage: translate('entity.validation.maxlength', { max: 17 }) },
                  }}
                />
                <UncontrolledTooltip target="shortNameLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.shortName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionLabel" for="eclass-value-definition">
                  <Translate contentKey="eclassApp.eclassValue.definition">Definition</Translate>
                </Label>
                <AvField
                  id="eclass-value-definition"
                  type="text"
                  name="definition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.definition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="referenceLabel" for="eclass-value-reference">
                  <Translate contentKey="eclassApp.eclassValue.reference">Reference</Translate>
                </Label>
                <AvField
                  id="eclass-value-reference"
                  type="text"
                  name="reference"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="referenceLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.reference" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOLanguageCodeLabel" for="eclass-value-iSOLanguageCode">
                  <Translate contentKey="eclassApp.eclassValue.iSOLanguageCode">I SO Language Code</Translate>
                </Label>
                <AvField
                  id="eclass-value-iSOLanguageCode"
                  type="text"
                  name="iSOLanguageCode"
                  validate={{
                    maxLength: { value: 2, errorMessage: translate('entity.validation.maxlength', { max: 2 }) },
                  }}
                />
                <UncontrolledTooltip target="iSOLanguageCodeLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.iSOLanguageCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOCountryCodeLabel" for="eclass-value-iSOCountryCode">
                  <Translate contentKey="eclassApp.eclassValue.iSOCountryCode">I SO Country Code</Translate>
                </Label>
                <AvInput
                  id="eclass-value-iSOCountryCode"
                  type="select"
                  className="form-control"
                  name="iSOCountryCode"
                  value={(!isNew && eclassValueEntity.iSOCountryCode) || 'DE'}
                >
                  <option value="DE">{translate('eclassApp.ISOCountryCode.DE')}</option>
                  <option value="US">{translate('eclassApp.ISOCountryCode.US')}</option>
                </AvInput>
                <UncontrolledTooltip target="iSOCountryCodeLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.iSOCountryCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiVALabel" for="eclass-value-irdiVA">
                  <Translate contentKey="eclassApp.eclassValue.irdiVA">Irdi VA</Translate>
                </Label>
                <AvField
                  id="eclass-value-irdiVA"
                  type="text"
                  name="irdiVA"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiVALabel">
                  <Translate contentKey="eclassApp.eclassValue.help.irdiVA" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="dataTypeLabel" for="eclass-value-dataType">
                  <Translate contentKey="eclassApp.eclassValue.dataType">Data Type</Translate>
                </Label>
                <AvInput
                  id="eclass-value-dataType"
                  type="select"
                  className="form-control"
                  name="dataType"
                  value={(!isNew && eclassValueEntity.dataType) || 'STRING'}
                >
                  <option value="STRING">{translate('eclassApp.EclassDataType.STRING')}</option>
                  <option value="STRING_TRANSLATABLE">{translate('eclassApp.EclassDataType.STRING_TRANSLATABLE')}</option>
                  <option value="REAL_MEASURE">{translate('eclassApp.EclassDataType.REAL_MEASURE')}</option>
                  <option value="REAL_COUNT">{translate('eclassApp.EclassDataType.REAL_COUNT')}</option>
                  <option value="REAL_CURRENCY">{translate('eclassApp.EclassDataType.REAL_CURRENCY')}</option>
                  <option value="INTEGER_MEASURE">{translate('eclassApp.EclassDataType.INTEGER_MEASURE')}</option>
                  <option value="INTEGER_COUNT">{translate('eclassApp.EclassDataType.INTEGER_COUNT')}</option>
                  <option value="INTEGER_CURRENCY">{translate('eclassApp.EclassDataType.INTEGER_CURRENCY')}</option>
                  <option value="BOOLEAN">{translate('eclassApp.EclassDataType.BOOLEAN')}</option>
                  <option value="URL">{translate('eclassApp.EclassDataType.URL')}</option>
                  <option value="RATIONAL">{translate('eclassApp.EclassDataType.RATIONAL')}</option>
                  <option value="RATIONAL_MEASURE">{translate('eclassApp.EclassDataType.RATIONAL_MEASURE')}</option>
                  <option value="TIME">{translate('eclassApp.EclassDataType.TIME')}</option>
                  <option value="TIMESTAMP">{translate('eclassApp.EclassDataType.TIMESTAMP')}</option>
                  <option value="DATE">{translate('eclassApp.EclassDataType.DATE')}</option>
                </AvInput>
                <UncontrolledTooltip target="dataTypeLabel">
                  <Translate contentKey="eclassApp.eclassValue.help.dataType" />
                </UncontrolledTooltip>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/eclass-value" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  eclassValueEntity: storeState.eclassValue.entity,
  loading: storeState.eclassValue.loading,
  updating: storeState.eclassValue.updating,
  updateSuccess: storeState.eclassValue.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EclassValueUpdate);
