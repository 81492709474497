import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import React , { useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  Container,
  CardTitle,
  Row,
  Col
} from 'reactstrap';
import axios from 'axios';
import Autosuggest from 'react-autosuggest'
import { set } from 'lodash';
import './eclass-search-box.scss';
const apiUrl = 'api/classifications';

export const EclassSearchBox = () => {
  const [value, setValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);

  function getSuggestions(searchValue) {
    // eslint-disable-next-line no-console
    console.log("getSuggestions: " + searchValue);
    if (searchValue.length < 3) {
      return;
    }
    if (selectedValue) {
      return;
    }
    // get the list of attributes
    const requestUrl = encodeURI(`${apiUrl}/search?searchString=` + searchValue);
    axios.get(requestUrl).then(response => {
      const returnList = response.data;
      setSuggestions(returnList);
      return returnList;
    });
  }

  React.useEffect(() => {
    if (selectedValue) {
      window.location.href = '/eclass?classCode='+selectedValue;
    }
  }, [selectedValue]);

  return (
  <React.Fragment>
    <Autosuggest 
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        // eslint-disable-next-line no-shadow
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          // eslint-disable-next-line no-console
          console.log("onSuggestionsFetchRequested: " + value)
          getSuggestions(value);
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          setSelectedValue(suggestionValue);      
        }}
        getSuggestionValue={suggestion => suggestion.codedName}
        inputProps={{
          placeholder: "Suche eingeben...",
          // eslint-disable-next-line object-shorthand
          value: value,
          onChange(_, { newValue, method }) {
          // eslint-disable-next-line no-console
          // console.log("onChnage: " + newValue, method)
            // setValue(newValue.codedName);
            if (!newValue) {
              setValue("");
            }
          }
        }}
        highlightFirstSuggestion={true}
        renderSuggestion={suggestion => <div>{suggestion.codedName} - {suggestion.prefferedName}</div>} 
      />
  </React.Fragment>
  );
};

export default EclassSearchBox; 