import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './unit.reducer';
import { IUnit } from 'app/shared/model/unit.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IUnitUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const UnitUpdate = (props: IUnitUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { unitEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/unit' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...unitEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.unit.home.createOrEditLabel">
            <Translate contentKey="eclassApp.unit.home.createOrEditLabel">Create or edit a Unit</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : unitEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="unit-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="unit-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="structuredNamingLabel" for="unit-structuredNaming">
                  <Translate contentKey="eclassApp.unit.structuredNaming">Structured Naming</Translate>
                </Label>
                <AvField
                  id="unit-structuredNaming"
                  type="text"
                  name="structuredNaming"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="structuredNamingLabel">
                  <Translate contentKey="eclassApp.unit.help.structuredNaming" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="shortNameLabel" for="unit-shortName">
                  <Translate contentKey="eclassApp.unit.shortName">Short Name</Translate>
                </Label>
                <AvField
                  id="unit-shortName"
                  type="text"
                  name="shortName"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="shortNameLabel">
                  <Translate contentKey="eclassApp.unit.help.shortName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionLabel" for="unit-definition">
                  <Translate contentKey="eclassApp.unit.definition">Definition</Translate>
                </Label>
                <AvField
                  id="unit-definition"
                  type="text"
                  name="definition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionLabel">
                  <Translate contentKey="eclassApp.unit.help.definition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="sourceLabel" for="unit-source">
                  <Translate contentKey="eclassApp.unit.source">Source</Translate>
                </Label>
                <AvField
                  id="unit-source"
                  type="text"
                  name="source"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="sourceLabel">
                  <Translate contentKey="eclassApp.unit.help.source" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="commentLabel" for="unit-comment">
                  <Translate contentKey="eclassApp.unit.comment">Comment</Translate>
                </Label>
                <AvField
                  id="unit-comment"
                  type="text"
                  name="comment"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="commentLabel">
                  <Translate contentKey="eclassApp.unit.help.comment" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="sINotationLabel" for="unit-sINotation">
                  <Translate contentKey="eclassApp.unit.sINotation">S I Notation</Translate>
                </Label>
                <AvField
                  id="unit-sINotation"
                  type="text"
                  name="sINotation"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="sINotationLabel">
                  <Translate contentKey="eclassApp.unit.help.sINotation" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="sINameLabel" for="unit-sIName">
                  <Translate contentKey="eclassApp.unit.sIName">S I Name</Translate>
                </Label>
                <AvField
                  id="unit-sIName"
                  type="text"
                  name="sIName"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="sINameLabel">
                  <Translate contentKey="eclassApp.unit.help.sIName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="sINNotationLabel" for="unit-sINNotation">
                  <Translate contentKey="eclassApp.unit.sINNotation">S IN Notation</Translate>
                </Label>
                <AvField
                  id="unit-sINNotation"
                  type="text"
                  name="sINNotation"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="sINNotationLabel">
                  <Translate contentKey="eclassApp.unit.help.sINNotation" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="eCENameLabel" for="unit-eCEName">
                  <Translate contentKey="eclassApp.unit.eCEName">E CE Name</Translate>
                </Label>
                <AvField
                  id="unit-eCEName"
                  type="text"
                  name="eCEName"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="eCENameLabel">
                  <Translate contentKey="eclassApp.unit.help.eCEName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="eCECodeLabel" for="unit-eCECode">
                  <Translate contentKey="eclassApp.unit.eCECode">E CE Code</Translate>
                </Label>
                <AvField
                  id="unit-eCECode"
                  type="text"
                  name="eCECode"
                  validate={{
                    maxLength: { value: 3, errorMessage: translate('entity.validation.maxlength', { max: 3 }) },
                  }}
                />
                <UncontrolledTooltip target="eCECodeLabel">
                  <Translate contentKey="eclassApp.unit.help.eCECode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="nISTNameLabel" for="unit-nISTName">
                  <Translate contentKey="eclassApp.unit.nISTName">N IST Name</Translate>
                </Label>
                <AvField
                  id="unit-nISTName"
                  type="text"
                  name="nISTName"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="nISTNameLabel">
                  <Translate contentKey="eclassApp.unit.help.nISTName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iECClassificationLabel" for="unit-iECClassification">
                  <Translate contentKey="eclassApp.unit.iECClassification">I EC Classification</Translate>
                </Label>
                <AvField
                  id="unit-iECClassification"
                  type="text"
                  name="iECClassification"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="iECClassificationLabel">
                  <Translate contentKey="eclassApp.unit.help.iECClassification" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiUNLabel" for="unit-irdiUN">
                  <Translate contentKey="eclassApp.unit.irdiUN">Irdi UN</Translate>
                </Label>
                <AvField
                  id="unit-irdiUN"
                  type="text"
                  name="irdiUN"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiUNLabel">
                  <Translate contentKey="eclassApp.unit.help.irdiUN" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="nameOfDedicatedQuantityLabel" for="unit-nameOfDedicatedQuantity">
                  <Translate contentKey="eclassApp.unit.nameOfDedicatedQuantity">Name Of Dedicated Quantity</Translate>
                </Label>
                <AvField
                  id="unit-nameOfDedicatedQuantity"
                  type="text"
                  name="nameOfDedicatedQuantity"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="nameOfDedicatedQuantityLabel">
                  <Translate contentKey="eclassApp.unit.help.nameOfDedicatedQuantity" />
                </UncontrolledTooltip>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/unit" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  unitEntity: storeState.unit.entity,
  loading: storeState.unit.loading,
  updating: storeState.unit.updating,
  updateSuccess: storeState.unit.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UnitUpdate);
