import { ISpecification } from 'app/shared/model/specification.model';
import { IAttribute } from 'app/shared/model/attribute.model';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
import { EclassDataType } from 'app/shared/model/enumerations/eclass-data-type.model';

export interface ISpecificationAttributeData {
  id?: number;
  data?: string;
  dataType?: EclassDataType;
  irdiSD?: ISpecification;
  irdiPR?: IAttribute;
  irdiVA?: IEclassValue;
}

export const defaultValue: Readonly<ISpecificationAttributeData> = {};
