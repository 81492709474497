import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './classification.reducer';
import { IClassification } from 'app/shared/model/classification.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClassificationDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClassificationDetail = (props: IClassificationDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { classificationEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.classification.detail.title">Classification</Translate> [<b>{classificationEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="supplier">
              <Translate contentKey="eclassApp.classification.supplier">Supplier</Translate>
            </span>
            <UncontrolledTooltip target="supplier">
              <Translate contentKey="eclassApp.classification.help.supplier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.supplier}</dd>
          <dt>
            <span id="idCC">
              <Translate contentKey="eclassApp.classification.idCC">Id CC</Translate>
            </span>
            <UncontrolledTooltip target="idCC">
              <Translate contentKey="eclassApp.classification.help.idCC" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.idCC}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="eclassApp.classification.identifier">Identifier</Translate>
            </span>
            <UncontrolledTooltip target="identifier">
              <Translate contentKey="eclassApp.classification.help.identifier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.identifier}</dd>
          <dt>
            <span id="versionNumber">
              <Translate contentKey="eclassApp.classification.versionNumber">Version Number</Translate>
            </span>
            <UncontrolledTooltip target="versionNumber">
              <Translate contentKey="eclassApp.classification.help.versionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.versionNumber}</dd>
          <dt>
            <span id="versionDate">
              <Translate contentKey="eclassApp.classification.versionDate">Version Date</Translate>
            </span>
            <UncontrolledTooltip target="versionDate">
              <Translate contentKey="eclassApp.classification.help.versionDate" />
            </UncontrolledTooltip>
          </dt>
          <dd>
            {classificationEntity.versionDate ? (
              <TextFormat value={classificationEntity.versionDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="revisionNumber">
              <Translate contentKey="eclassApp.classification.revisionNumber">Revision Number</Translate>
            </span>
            <UncontrolledTooltip target="revisionNumber">
              <Translate contentKey="eclassApp.classification.help.revisionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.revisionNumber}</dd>
          <dt>
            <span id="codedName">
              <Translate contentKey="eclassApp.classification.codedName">Coded Name</Translate>
            </span>
            <UncontrolledTooltip target="codedName">
              <Translate contentKey="eclassApp.classification.help.codedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.codedName}</dd>
          <dt>
            <span id="prefferedName">
              <Translate contentKey="eclassApp.classification.prefferedName">Preffered Name</Translate>
            </span>
            <UncontrolledTooltip target="prefferedName">
              <Translate contentKey="eclassApp.classification.help.prefferedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.prefferedName}</dd>
          <dt>
            <span id="definition">
              <Translate contentKey="eclassApp.classification.definition">Definition</Translate>
            </span>
            <UncontrolledTooltip target="definition">
              <Translate contentKey="eclassApp.classification.help.definition" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.definition}</dd>
          <dt>
            <span id="iSOLanguageCode">
              <Translate contentKey="eclassApp.classification.iSOLanguageCode">I SO Language Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOLanguageCode">
              <Translate contentKey="eclassApp.classification.help.iSOLanguageCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.iSOLanguageCode}</dd>
          <dt>
            <span id="iSOCountryCode">
              <Translate contentKey="eclassApp.classification.iSOCountryCode">I SO Country Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOCountryCode">
              <Translate contentKey="eclassApp.classification.help.iSOCountryCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.iSOCountryCode}</dd>
          <dt>
            <span id="note">
              <Translate contentKey="eclassApp.classification.note">Note</Translate>
            </span>
            <UncontrolledTooltip target="note">
              <Translate contentKey="eclassApp.classification.help.note" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.note}</dd>
          <dt>
            <span id="remark">
              <Translate contentKey="eclassApp.classification.remark">Remark</Translate>
            </span>
            <UncontrolledTooltip target="remark">
              <Translate contentKey="eclassApp.classification.help.remark" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.remark}</dd>
          <dt>
            <span id="level">
              <Translate contentKey="eclassApp.classification.level">Level</Translate>
            </span>
            <UncontrolledTooltip target="level">
              <Translate contentKey="eclassApp.classification.help.level" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.level}</dd>
          <dt>
            <span id="mKSubclass">
              <Translate contentKey="eclassApp.classification.mKSubclass">M K Subclass</Translate>
            </span>
            <UncontrolledTooltip target="mKSubclass">
              <Translate contentKey="eclassApp.classification.help.mKSubclass" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.mKSubclass ? 'true' : 'false'}</dd>
          <dt>
            <span id="mKKeyword">
              <Translate contentKey="eclassApp.classification.mKKeyword">M K Keyword</Translate>
            </span>
            <UncontrolledTooltip target="mKKeyword">
              <Translate contentKey="eclassApp.classification.help.mKKeyword" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.mKKeyword ? 'true' : 'false'}</dd>
          <dt>
            <span id="mKBSA">
              <Translate contentKey="eclassApp.classification.mKBSA">M KBSA</Translate>
            </span>
            <UncontrolledTooltip target="mKBSA">
              <Translate contentKey="eclassApp.classification.help.mKBSA" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.mKBSA}</dd>
          <dt>
            <span id="irdiCC">
              <Translate contentKey="eclassApp.classification.irdiCC">Irdi CC</Translate>
            </span>
            <UncontrolledTooltip target="irdiCC">
              <Translate contentKey="eclassApp.classification.help.irdiCC" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationEntity.irdiCC}</dd>
        </dl>
        <Button tag={Link} to="/classification" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/classification/${classificationEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ classification }: IRootState) => ({
  classificationEntity: classification.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationDetail);
