import { Moment } from 'moment';
import { ISOCountryCode } from 'app/shared/model/enumerations/iso-country-code.model';

export interface IClassification {
  id?: number;
  supplier?: string;
  idCC?: string;
  identifier?: string;
  versionNumber?: number;
  versionDate?: string;
  revisionNumber?: number;
  codedName?: string;
  prefferedName?: string;
  definition?: string;
  iSOLanguageCode?: string;
  iSOCountryCode?: ISOCountryCode;
  note?: string;
  remark?: string;
  level?: number;
  mKSubclass?: boolean;
  mKKeyword?: boolean;
  mKBSA?: number;
  irdiCC?: string;
}

export const defaultValue: Readonly<IClassification> = {
  mKSubclass: false,
  mKKeyword: false,
};
