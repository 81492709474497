import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import Specification from './specification';
import SpecificationDetail from './specification-detail';
import SpecificationUpdate from './specification-update';
import SpecificationDeleteDialog from './specification-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={SpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={SpecificationUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={SpecificationDetail} />
      <ErrorBoundaryRoute path={match.url} component={Specification} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={SpecificationDeleteDialog} />
  </>
);

export default Routes;
