import React, { useState } from 'react';
import { Form, FormGroup, Row, Col, Label, Input, FormText, Card, Tooltip, Badge, Table, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { connect } from 'react-redux';
import './eclass-main.scss';
import './eclass-classification-attribute-list.scss';
import { Translate, getUrlParameter } from 'react-jhipster';
import { WithContext as ReactTags } from 'react-tag-input';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { getEntity, updateEntity, createEntity, reset } from 'app/entities/classification/classification.reducer';
import { IAttribute } from 'app/shared/model/attribute.model';
import { ISpecificationAttributeData } from 'app/shared/model/specification-attribute-data.model';
import EclassAttributeModal from './eclass-attribute-modal';
import EclassClassificationAttributeListItem from './eclass-classification-attribute-list-item';
import { IRootState } from 'app/shared/reducers';
import { useEclassState, EclassStateInterface } from './eclass-state-provider';
export interface IEclassMainProp extends StateProps, DispatchProps, RouteComponentProps<{ key: any }> {
  selectedClassification;
  setDatasheetList;
  datasheetList;
}

// export const ClassificationAttributeList = ({ selectedClassification, setDatasheetList, datasheetList }): any => {

export const ClassificationAttributeList = (props: IEclassMainProp) => {
  const apiUrl = 'api/classifications';
  const { selectedClassification, setDatasheetList, datasheetList } = props;
  const [attributeTableList, setAttributeTableList] = useState(null);
  const [attributeList, setAttributeList] = useState(null);
  const [addAttribute, setAddAttribute] = useState(null);
  const [specificationAttributeDataList, setSpecificationAttributeDataList] = useState(null);
  const [keywordList, setKeywordList] = useState(null);
  const [isEditable, setIsEditable] = useState(selectedClassification?.codedName?.startsWith('9'));
  const [isNew, setIsNew] = useState(selectedClassification?.id == null);
  const [tags, setTags] = useState([]);
  const [isClassificationSave, setIsClassificationSave] = useState(null);

  const { state } = useEclassState();
  const { setState } = useEclassState();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];

  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    // re-render
    setTags(newTags);
  };

  const handleTagClick = index => {
    // eslint-disable-next-line no-console
    console.log('The tag at index ' + index + ' was clicked');
  };

  const suggestions2 = selectedClassification?.keywordList?.map(keyword => {
    return {
      id: keyword.id.toString(),
      text: keyword.value,
    };
  });

  const suggestions = [
    { id: '1', text: 'mango' },
    { id: '2', text: 'pineapple' },
    { id: '3', text: 'orange' },
    { id: '4', text: 'pear' },
  ];

  const removeAttribute = attribute => {
    // use axios to delete!
    const requestUrl = encodeURI(`api/classifications/${selectedClassification.id}/attribute/${attribute.id}`);
      axios.delete<IAttribute[]>(requestUrl).then(response => {
        const returnList = response.data;
        // eslint-disable-next-line no-console
        console.log('EclassClassificationAttributeList: delete successfull:', response);       
        
        // remove it from the list
        const newList = attributeList.filter((item) => item.id !== attribute?.id);
        setAttributeList(newList);   
      });
  };

  React.useEffect(() => {
    const list = attributeList
      ?.sort((a, b) => a.prefferedName.toLowerCase().localeCompare(b.prefferedName.toLowerCase()))
      .map(function (attribute) {
        return <EclassClassificationAttributeListItem key={attribute.id} attribute={attribute} removeAttribute={removeAttribute} />;
      });
    setAttributeTableList(list);
  }, [attributeList]);

  React.useEffect(() => {
    let mounted = true;

    // eslint-disable-next-line no-console
    console.log('keyword list', keywordList);

    if (selectedClassification?.id != null) {
      setIsNew(false);
    }
    if (mounted) {
      if (selectedClassification && selectedClassification.codedName?.startsWith('9')) {
        // props.getEntity(selectedClassification.id);
      } else if (selectedClassification) {
        props.reset();
      }
    }

    if (selectedClassification && typeof selectedClassification.id !== 'undefined') {
      // get the list of attributes
      const requestUrl = encodeURI(`${apiUrl}/${selectedClassification.id}/attributes`);
      if (mounted) {
        axios.get<IAttribute[]>(requestUrl).then(response => {
          const returnList = response.data;
          if (mounted) setAttributeList(response.data);
        });
      }

      // get the keywords for the classification
      if (selectedClassification.keywordList) {
        const list = selectedClassification.keywordList?.map(function (keyword) {
          return <React.Fragment key={keyword.id}>{keyword.value}, </React.Fragment>;
        });
        setKeywordList(list);
        const tagList = selectedClassification?.keywordList?.map(keyword => {
          return {
            id: keyword.id.toString(),
            text: keyword.value,
          };
        });
        setTags(tagList);
      } else {
        setKeywordList(null);
      }

      if (selectedClassification.codedName?.startsWith('9')) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    } else {
      // eslint-disable-next-line no-console
      // console.log('ELSE HERE');
    }
    return () => {
      mounted = false;
    };
  }, [selectedClassification]);

  React.useEffect(() => {
    if (addAttribute) {
      if (!attributeList) {
        setAttributeList([].concat(addAttribute));
      } else {
        setAttributeList(attributeList.concat(addAttribute));
      }

      if (!selectedClassification.attributeList) {
        selectedClassification.attributeList = [].concat(addAttribute);
      } else {
        selectedClassification.attributeList = selectedClassification.attributeList.concat(addAttribute);
      }
    }
  }, [addAttribute]);

  // specificationAttributeDataList
  function addAllTempAttributes(attributes) {
    const data = {
      tempAttributeListToAdd: [],
    };
    if (state.attributeListToAdd) {
      data.tempAttributeListToAdd = state.tempAttributeListToAdd;
    }
    // loop through all!!!
    attributes.forEach(attribute => {
      data.tempAttributeListToAdd.push(attribute.irdiPR);
    });
    // and update the state with the list to add
    setState(prev => ({ ...prev, ...data }));
  }

  React.useEffect(() => {
    let wipList = [];
    attributeList?.map(function (attribute) {
      const newSpecificationAttributeData: ISpecificationAttributeData = {
        // irdiSD: selectedSpecification,
        irdiPR: attribute,
      };
      if (wipList) {
        wipList = wipList.concat(newSpecificationAttributeData);
      } else {
        wipList = [].concat(wipList);
      }
    });

    setSpecificationAttributeDataList(wipList);
    addAllTempAttributes(wipList);
  }, [attributeList]);

  // specificationAttributeDataList
  function addAllAttributes(attributes) {
    const data = {
      attributeListToAdd: [],
    };
    if (state.attributeListToAdd) {
      data.attributeListToAdd = state.attributeListToAdd;
    }
    // loop through all!!!
    attributes.forEach(attribute => {
      data.attributeListToAdd.push(attribute.irdiPR);
    });
    // and update the state with the list to add
    setState(prev => ({ ...prev, ...data }));
  }

  function addAllSpecificationAttributes() {
    addAllAttributes(specificationAttributeDataList);
  }

  React.useEffect(() => {
    if (props.updateSuccess) {
      Object.keys(props.storedClassification.entity).forEach(key => {
        selectedClassification[key] = props.storedClassification.entity[key];
      });
      // reload with new class to get full classification tree
      window.location.href = '/eclass?classCode=' + props.storedClassification.entity.codedName;
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    // eslint-disable-next-line no-console
    console.log('SAVE:', selectedClassification?.id, isNew);

    if (errors.length === 0 && isClassificationSave) {
      const entity = {
        ...selectedClassification,
        ...values,
      };
      // eslint-disable-next-line no-console
      console.log('save', selectedClassification, entity);
      if (!entity.id) {
        // eslint-disable-next-line no-console
        console.log('create', selectedClassification, entity);

        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
      setIsClassificationSave(null);
    }
  };

  const handleSaveClick = event => {
    setIsClassificationSave(true);
  };

  return (
    <React.Fragment>
      {selectedClassification && (
        <Card>
          <AvForm onSubmit={saveEntity}>
            <FormGroup row>
              <Label sm={3} for="selectedClassification">
                <Translate contentKey="eclass-main.classification">Klassifikation</Translate>:
              </Label>
              <Col sm={9}>
                {isEditable && (
                  <FormText className={'float-right'}>
                    <Button color="primary" type="submit" onClick={handleSaveClick}>
                      Speichern
                    </Button>
                  </FormText>
                )}
                <Input
                  plaintext={true}
                  className="form-control-plaintext w-75"
                  type="selectedClassification"
                  name="selectedClassification"
                  id="selectedClassification"
                  placeholder="Klassifikation hinzufügen"
                  value={
                    typeof selectedClassification.id !== 'undefined'
                      ? selectedClassification.codedName + '#' + selectedClassification.irdiCC
                      : ''
                  }
                  readOnly={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} for="selectedClassificationName">
                <Translate contentKey="eclass-main.classification.name">Bevorzugte Benneung</Translate>:
              </Label>
              <Col sm={9}>
                <Input
                  key={selectedClassification.id}
                  readOnly={!isEditable}
                  plaintext={!isEditable}
                  type="selectedClassificationName"
                  name="selectedClassificationName"
                  id="selectedClassificationName"
                  // value={selectedClassification != null ? selectedClassification.prefferedName : ''}
                  defaultValue={selectedClassification != null ? selectedClassification.prefferedName : ''}
                  onChange={node => (selectedClassification.prefferedName = node.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} for="selectedClassificationDefinition">
                <Translate contentKey="eclass-main.classification.definition">Definition</Translate>:
              </Label>
              <Col sm={9}>
                <Input
                  key={selectedClassification.id}
                  readOnly={!isEditable}
                  plaintext={!isEditable}
                  type="textarea"
                  name="selectedClassificationDefinition"
                  id="selectedClassificationDefinition"
                  defaultValue={selectedClassification != null ? selectedClassification.definition : ''}
                  onChange={node => (selectedClassification.definition = node.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label sm={3} for="selectedClassificationKeywords">
                <Translate contentKey="eclass-main.classification.keywords">Schlagwörter</Translate>:
              </Label>
              <Col sm={9}>
                <ReactTags
                  tags={tags}
                  suggestions={suggestions}
                  delimiters={delimiters}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleDrag={handleDrag}
                  handleTagClick={handleTagClick}
                  inputFieldPosition="inline"
                  autocomplete
                  inline={true}
                  readOnly={!isEditable}
                  classNames={{
                    tag: 'badge badge-secondary',
                  }}
                />
              </Col>
            </FormGroup>

            {attributeTableList?.length >= 0 && (
              <FormGroup>
                <Label for="selectedClassificationName">
                  <Translate contentKey="eclass-main.classification.attributeList">Merkmalliste</Translate>
                  <Badge color="secondary">{attributeTableList?.length}</Badge>
                </Label>
                <div className="float-right">
                  <EclassAttributeModal
                    buttonLabel="Merkmal hinzufügen"
                    addAttribute={setAddAttribute}
                    selectedClassificationId={selectedClassification.id}
                    selectedClassification={selectedClassification}
                  ></EclassAttributeModal>
                </div>
                <div className="float-right" style={{ marginRight: '5px' }}>
                  <Button
                    color="secondary"
                    size="sm"
                    className="float-right"
                    onClick={() => addAllAttributes(specificationAttributeDataList)}
                  >
                    alle Merkmale dem Stammdatenblatt hinzufügen
                  </Button>
                </div>
                <Card style={{ padding: 0 }}>
                  <div
                    style={{
                      maxHeight: '300px',
                      overflow: 'scroll',
                      //                  overflowY: 'auto',
                      //                  border: '1px solid'
                    }}
                  >
                    <Table bordered className="table-sm">
                      <tbody>{attributeTableList}</tbody>
                    </Table>
                  </div>
                </Card>
              </FormGroup>
            )}
          </AvForm>
        </Card>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (storeState: IRootState, props) => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
  loading: storeState.classification.loading,
  updating: storeState.classification.updating,
  updateSuccess: storeState.classification.updateSuccess,
  storedClassification: storeState.classification,
  buttonLabel: props.buttonLabel,
  className: props.className,
  selectedClassification: props.selectedClassification,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationAttributeList);
