import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './keyword.reducer';
import { IKeyword } from 'app/shared/model/keyword.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IKeywordDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const KeywordDetail = (props: IKeywordDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { keywordEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.keyword.detail.title">Keyword</Translate> [<b>{keywordEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="supplierKW">
              <Translate contentKey="eclassApp.keyword.supplierKW">Supplier KW</Translate>
            </span>
            <UncontrolledTooltip target="supplierKW">
              <Translate contentKey="eclassApp.keyword.help.supplierKW" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.supplierKW}</dd>
          <dt>
            <span id="identifier">
              <Translate contentKey="eclassApp.keyword.identifier">Identifier</Translate>
            </span>
            <UncontrolledTooltip target="identifier">
              <Translate contentKey="eclassApp.keyword.help.identifier" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.identifier}</dd>
          <dt>
            <span id="versionNumber">
              <Translate contentKey="eclassApp.keyword.versionNumber">Version Number</Translate>
            </span>
            <UncontrolledTooltip target="versionNumber">
              <Translate contentKey="eclassApp.keyword.help.versionNumber" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.versionNumber}</dd>
          <dt>
            <span id="idCC">
              <Translate contentKey="eclassApp.keyword.idCC">Id CC</Translate>
            </span>
            <UncontrolledTooltip target="idCC">
              <Translate contentKey="eclassApp.keyword.help.idCC" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.idCC}</dd>
          <dt>
            <span id="value">
              <Translate contentKey="eclassApp.keyword.value">Value</Translate>
            </span>
            <UncontrolledTooltip target="value">
              <Translate contentKey="eclassApp.keyword.help.value" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.value}</dd>
          <dt>
            <span id="explanation">
              <Translate contentKey="eclassApp.keyword.explanation">Explanation</Translate>
            </span>
            <UncontrolledTooltip target="explanation">
              <Translate contentKey="eclassApp.keyword.help.explanation" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.explanation}</dd>
          <dt>
            <span id="iSOLanguageCode">
              <Translate contentKey="eclassApp.keyword.iSOLanguageCode">I SO Language Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOLanguageCode">
              <Translate contentKey="eclassApp.keyword.help.iSOLanguageCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.iSOLanguageCode}</dd>
          <dt>
            <span id="iSOCountryCode">
              <Translate contentKey="eclassApp.keyword.iSOCountryCode">I SO Country Code</Translate>
            </span>
            <UncontrolledTooltip target="iSOCountryCode">
              <Translate contentKey="eclassApp.keyword.help.iSOCountryCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.iSOCountryCode}</dd>
          <dt>
            <span id="typeOfTargetSE">
              <Translate contentKey="eclassApp.keyword.typeOfTargetSE">Type Of Target SE</Translate>
            </span>
            <UncontrolledTooltip target="typeOfTargetSE">
              <Translate contentKey="eclassApp.keyword.help.typeOfTargetSE" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.typeOfTargetSE}</dd>
          <dt>
            <span id="irdiTarget">
              <Translate contentKey="eclassApp.keyword.irdiTarget">Irdi Target</Translate>
            </span>
            <UncontrolledTooltip target="irdiTarget">
              <Translate contentKey="eclassApp.keyword.help.irdiTarget" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.irdiTarget}</dd>
          <dt>
            <span id="irdiKW">
              <Translate contentKey="eclassApp.keyword.irdiKW">Irdi KW</Translate>
            </span>
            <UncontrolledTooltip target="irdiKW">
              <Translate contentKey="eclassApp.keyword.help.irdiKW" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.irdiKW}</dd>
          <dt>
            <span id="typeOfSE">
              <Translate contentKey="eclassApp.keyword.typeOfSE">Type Of SE</Translate>
            </span>
            <UncontrolledTooltip target="typeOfSE">
              <Translate contentKey="eclassApp.keyword.help.typeOfSE" />
            </UncontrolledTooltip>
          </dt>
          <dd>{keywordEntity.typeOfSE}</dd>
        </dl>
        <Button tag={Link} to="/keyword" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/keyword/${keywordEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ keyword }: IRootState) => ({
  keywordEntity: keyword.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(KeywordDetail);
