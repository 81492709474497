import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ClassificationAttributeXref from './classification-attribute-xref';
import ClassificationAttributeXrefDetail from './classification-attribute-xref-detail';
import ClassificationAttributeXrefUpdate from './classification-attribute-xref-update';
import ClassificationAttributeXrefDeleteDialog from './classification-attribute-xref-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ClassificationAttributeXrefUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ClassificationAttributeXrefUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ClassificationAttributeXrefDetail} />
      <ErrorBoundaryRoute path={match.url} component={ClassificationAttributeXref} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ClassificationAttributeXrefDeleteDialog} />
  </>
);

export default Routes;
