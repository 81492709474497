import React, { useState } from 'react';
import { FormGroup, Label, Input, FormText, Tooltip, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EclassAttributeModal from './eclass-attribute-modal';
import { useEclassState, EclassStateInterface } from './eclass-state-provider';
import { spawn } from 'child_process';
import { IAttribute } from 'app/shared/model/attribute.model';

export const EclassClassificationAttributeListItem = props => {
  const { attribute, removeAttribute } = props;
  const [attributeTooltipOpen, setAttributeTooltipOpen] = useState(false);
  const { setState } = useEclassState();
  const { state } = useEclassState();
  const toggleAttributeTooltip = () => setAttributeTooltipOpen(!attributeTooltipOpen);

  const addAttribute = () => {
    const data = {
      attributeListToAdd: [],
    };
    if (state.attributeListToAdd) {
      data.attributeListToAdd = state.attributeListToAdd;
    }
    data.attributeListToAdd.push(attribute);
    setState(prev => ({ ...prev, ...data }));
  };

  return (
    <React.Fragment>
      <tr className={`${attribute?.fromParent ? 'table-secondary' : ''}`}>
        <td scope="row" style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
          {attribute?.fromParent ? <i>{attribute.prefferedName}</i> : <>{attribute.prefferedName}</>}

          <span style={{ paddingLeft: '5px' }} className="float-right">
            <EclassAttributeModal buttonLabel="edit" className="className" attribute={attribute}></EclassAttributeModal>
          </span>

          <span className="float-right">
            <Tooltip placement="left" isOpen={attributeTooltipOpen} target={attribute?.idPR + 'id'} toggle={toggleAttributeTooltip}>
              {attribute?.irdiPR}
            </Tooltip>
            {attribute?.fromParent ? (
              <></>
            ) : (
              <>
                <span style={{ paddingLeft: '5px' }} onClick={() => removeAttribute(attribute)}>
                  <FontAwesomeIcon icon="minus-circle" size="lg" />
                </span>
              </>
            )}
            <span style={{ paddingLeft: '5px' }} id={attribute?.idPR + 'id'}>
              <FontAwesomeIcon icon="info-circle" size="lg" />
            </span>
            {state.specificationInProgress ? (
              <span style={{ paddingLeft: '5px' }} onClick={addAttribute}>
                <FontAwesomeIcon icon="plus-circle" size="lg" />
              </span>
            ) : (
              <></>
            )}
          </span>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default EclassClassificationAttributeListItem;
