import { Moment } from 'moment';
import { ISOCountryCode } from 'app/shared/model/enumerations/iso-country-code.model';
import { EclassDataType } from 'app/shared/model/enumerations/eclass-data-type.model';

export interface IEclassValue {
  id?: number;
  supplier?: string;
  idVA?: string;
  identifier?: string;
  versionNumber?: number;
  revisionNumber?: number;
  versionDate?: string;
  prefferedName?: string;
  shortName?: string;
  definition?: string;
  reference?: string;
  iSOLanguageCode?: string;
  iSOCountryCode?: ISOCountryCode;
  irdiVA?: string;
  dataType?: EclassDataType;
}

export const defaultValue: Readonly<IEclassValue> = {};
