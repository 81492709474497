import { ISOCountryCode } from 'app/shared/model/enumerations/iso-country-code.model';
import { EclassTypeOfTargetSE } from 'app/shared/model/enumerations/eclass-type-of-target-se.model';
import { EclassTypeOfSE } from 'app/shared/model/enumerations/eclass-type-of-se.model';

export interface IKeyword {
  id?: number;
  supplierKW?: string;
  identifier?: string;
  versionNumber?: number;
  idCC?: string;
  value?: string;
  explanation?: string;
  iSOLanguageCode?: string;
  iSOCountryCode?: ISOCountryCode;
  typeOfTargetSE?: EclassTypeOfTargetSE;
  irdiTarget?: string;
  irdiKW?: string;
  typeOfSE?: EclassTypeOfSE;
}

export const defaultValue: Readonly<IKeyword> = {};
