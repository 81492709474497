import React, { useState } from 'react';

import axios from 'axios';
import './eclass-main.scss';
import { IClassification } from 'app/shared/model/classification.model';

import { Translate, getUrlParameter } from 'react-jhipster';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Form, FormGroup, Row, Col, CardHeader, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import SelectSearch from 'react-select-search';

import ClassificationAttributeList from './eclass-classification-attribute-list';
import EclassDatasheet from './eclass-datasheet';
import EclassSearchBox from './eclass-search-box';
import EclassClassificationSelect from './eclass-main-classification-select';

export interface IEclassMainProp extends StateProps, RouteComponentProps<{ key: any }> {}

export const EclassMain = (props: IEclassMainProp) => {
  const [selectedClassification, setSelectedClassification] = useState(null);
  const [classCode, setClassCode] = useState(getUrlParameter('classCode', props.location.search));
  const [datasheetList, setDatasheetList] = useState([]);

  const apiUrl = 'api/classifications';


  React.useEffect(() => {
    if (!classCode) {
      setClassCode('00000000');
    } 
    if (classCode.endsWith('XX')) {
      setClassCode(classCode.replace('XX','00'));
    }

    // eslint-disable-next-line no-console
    console.log('eclass-main init', classCode);
  }, []);

  // update the URL to the currently selected classification
  React.useEffect(() => {
    if (selectedClassification && typeof selectedClassification.codedName !== 'undefined') {
      props.history.push('/eclass?classCode=' + selectedClassification.codedName);
    }
    if (selectedClassification) {
      setClassCode(selectedClassification?.codedName);
    }
  }, [selectedClassification]);

  return (
    <React.Fragment>
      <Row>
        <Col md={6} lg={6}>
          <Form>
            <CardHeader tag="h4">
              <Translate contentKey="eclass-main.classification-search-title">Klassifikation Suchen</Translate>
              <span className="EclassSearchBox">
                <EclassSearchBox />
              </span>
            </CardHeader>

            <FormGroup>
              <EclassClassificationSelect
                level="1"
                setSelectedClassification={setSelectedClassification}
                setDatasheetList={setDatasheetList}
                selectedClassifcationCode={classCode}
              />
            </FormGroup>
            <FormGroup>
              <EclassClassificationSelect
                level="2"
                setSelectedClassification={setSelectedClassification}
                setDatasheetList={setDatasheetList}
                selectedClassifcationCode={classCode}
              />
            </FormGroup>
            <FormGroup>
              <EclassClassificationSelect
                level="3"
                setSelectedClassification={setSelectedClassification}
                setDatasheetList={setDatasheetList}
                selectedClassifcationCode={classCode}
              />
            </FormGroup>
            <FormGroup>
              <EclassClassificationSelect
                level="4"
                setSelectedClassification={setSelectedClassification}
                setDatasheetList={setDatasheetList}
                selectedClassifcationCode={classCode}
              />
            </FormGroup>
          </Form>

          <ClassificationAttributeList
            selectedClassification={selectedClassification}
            setDatasheetList={setDatasheetList}
            datasheetList={datasheetList}
          />
        </Col>
        <Col md={6} lg={6} style={{ width: '100%' }}>
          <EclassDatasheet datasheetList={datasheetList} selectedClassification={selectedClassification}></EclassDatasheet>
        </Col>
      </Row>
      {selectedClassification != null ? (
        <Row>
          <Col md={6} lg={6}>
            <Breadcrumb>
              <BreadcrumbItem>{selectedClassification.codedName}</BreadcrumbItem>
              <BreadcrumbItem>{selectedClassification.irdiCC}</BreadcrumbItem>
            </Breadcrumb>
          </Col>
        </Row>
      ) : (
        ''
      )}
    </React.Fragment>
  );
};

const mapStateToProps = storeState => ({
  account: storeState.authentication.account,
  isAuthenticated: storeState.authentication.isAuthenticated,
});

type StateProps = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(EclassMain);
