import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IEclassValue } from 'app/shared/model/eclass-value.model';
import { getEntity, updateEntity, createEntity, reset } from 'app/entities/attribute/attribute.reducer';
import { IAttribute } from 'app/shared/model/attribute.model';
import { IUnit } from 'app/shared/model/unit.model';
import EclassAttributeAddSearchBox from './eclass-attribute-add-search-box';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Badge, Input, Label, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { IRootState } from 'app/shared/reducers';
import { EclassDataType } from 'app/shared/model/enumerations/eclass-data-type.model';

export interface IAttributeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  buttonLabel;
  className;
  addAttribute;
  selectedClassificationId;
  selectedClassification;
}

const EclassAttributeModal = (props: IAttributeUpdateProps) => {
  const apiUrl = 'api/eclass-values';
  const xrefApiUrl = 'api/classification-attribute-xrefs';

  const [valueList, setValueList] = useState(null);
  const [attributeSearchedValue, setAttributeSearchedValue] = useState(null);
  const [selectedAttribute, setSelectedAttribute] = useState(null);
  const [dialogVariables, setDialogVariables] = useState({
    dialogState: 'EDIT',
    dialogTitle: 'Merkmal bearbeiten: ',
    dialogSaveButtonTitle: 'Speichern',
  });

  const {
    buttonLabel,
    className,
    attribute,
    selectedClassificationId,
    selectedClassification,
    attributeEntity,
    loading,
    updating,
    match,
  } = props;

  const [isNew, setIsNew] = useState(!attribute || !attribute.id);
  const readOnly = selectedAttribute?.id && selectedAttribute?.id !== 1 && isNew;
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    if (isNew) {
      props.reset();
      setSelectedAttribute(null);
      setAttributeSearchedValue(null);
    } else {
      if (attribute) {
        props.getEntity(attribute.id);
      } else {
        props.reset();
        setSelectedAttribute(null);
        setAttributeSearchedValue(null);
      }
    }
  };

  const addAttributeToClassification = () => {
    if (selectedAttribute?.id && selectedClassification?.idCC) {
      // eslint-disable-next-line no-console
      console.log('eclass-attribute-model: addAttribute', selectedAttribute, selectedClassification);
      // props.addAttribute(selectedAttribute);

      // SAVE this in the database, too!!!
      // https://jasonwatmore.com/post/2021/06/25/axios-http-post-request-examples
      // POST request using axios with set headers

      // TODO: here decide not to save and show attribute
      if (selectedAttribute.id < 2) {
        // eslint-disable-next-line no-console
        console.log('eclass-attribute-model: addAttribute create attribute', attribute, selectedAttribute);
        const newAttribute: IAttribute = {} as IAttribute;
        newAttribute.dataType = selectedAttribute.dataType;
        newAttribute.prefferedName = selectedAttribute.prefferedName;
        const createdAttribute = Promise.resolve(props.createEntity(newAttribute));
        // wait for newAttribute to be created
        createdAttribute.then(v => {
          const resultObject: any = v;
          setSelectedAttribute(resultObject.value.data);
          // eslint-disable-next-line no-console
          console.log('Resolved ', selectedAttribute, createdAttribute, newAttribute, resultObject.value.data);

          const classificationAttributeXref = {
            idCC: selectedClassification.idCC,
            idPR: resultObject.value.data.idPR,
            classCodedName: selectedClassification.codedName,
            irdiCC: selectedClassification,
            irdiPR: resultObject.value.data,
          };
          const headers = {
            Authorization: 'Bearer my-token',
            'My-Custom-Header': 'foobar',
          };
          axios
            .post(`${xrefApiUrl}`, classificationAttributeXref, { headers })
            .then(response => {
              props.addAttribute(resultObject.value.data);
              toast.success('Merkmal hinzugefügt');
              setSelectedAttribute(null);
            })
            .catch(function (error) {
              // eslint-disable-next-line no-console
              console.log('Merkmal hinzufügen fehlgeschlagen: ', error.toJSON());
              toast.error('Merkmal hinzufügen fehlgeschlagen');
              setSelectedAttribute(null);
            });
        });
        setModal(false);
      } else {
        const classificationAttributeXref = {
          idCC: selectedClassification.idCC,
          idPR: selectedAttribute.idPR,
          classCodedName: selectedClassification.codedName,
          irdiCC: selectedClassification,
          irdiPR: selectedAttribute,
        };
        const headers = {
          Authorization: 'Bearer my-token',
          'My-Custom-Header': 'foobar',
        };
        axios
          .post(`${xrefApiUrl}`, classificationAttributeXref, { headers })
          .then(response => {
            props.addAttribute(selectedAttribute);
            toast.success('Merkmal hinzugefügt');
            setSelectedAttribute(null);
          })
          .catch(function (error) {
            // eslint-disable-next-line no-console
            console.log('Merkmal hinzufügen fehlgeschlagen: ', error.toJSON());
            toast.error('Merkmal hinzufügen fehlgeschlagen');
            setSelectedAttribute(null);
          });
        setModal(false);
      }
    }
  };

  React.useEffect(() => {
    if (attribute) {
      if (attribute.possibleValues) {
        const list = attribute.possibleValues
          .sort((a, b) => a.prefferedName.toLowerCase().localeCompare(b.prefferedName.toLowerCase()))
          .map(function (eclassValue) {
            return (
              <option key={eclassValue.id} value={eclassValue.identifier}>
                {eclassValue.prefferedName}
              </option>
            );
          });
        setValueList(list);
      }
      // props.getEntity(attribute.id);

      // make sure the selected attribute is set
      setSelectedAttribute(attribute);
    } else {
      // props.reset();
    }
  }, [attribute]);

  const handleClose = id => {
    if (id) {
      // eslint-disable-next-line no-console
      console.log('close', id, attributeEntity);
      setIsNew(!attributeEntity || !attributeEntity.id);
      setModal(!modal);
    }
  };

  React.useEffect(() => {
    // handle creating the Xref potentially

    if (props.updateSuccess && attribute?.id === attributeEntity.id) {
      // eslint-disable-next-line no-console
      console.log('updateSuccess', attribute.id, attributeEntity.id);
      handleClose(attribute.id);
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...attribute,
        ...values,
      };
      // eslint-disable-next-line no-console
      console.log('save', attribute, entity, attributeSearchedValue);
      if (attributeSearchedValue) {
        // eslint-disable-next-line no-console
        console.log('attributeSearchedValue NEW', attribute, entity, attributeSearchedValue);
        // a NEW attribute record
        const newAttribute: IAttribute = {} as IAttribute;
        newAttribute.id = 1;
        newAttribute.prefferedName = attributeSearchedValue;
        newAttribute.dataType = EclassDataType.STRING;
        newAttribute.numberOfCharacters = 32;
        setIsNew(false);
        setSelectedAttribute(newAttribute);
        setAttributeSearchedValue(null);
        // eslint-disable-next-line no-console
        console.log('attributeSearchedValue NEW2', selectedAttribute, newAttribute);
      } else if (isNew && !selectedAttribute?.id) {
        // TODO: here decide not to save and show attribute
        // eslint-disable-next-line no-console
        console.log('create attribute', attribute, entity, selectedAttribute);
        const newAttribute: IAttribute = {} as IAttribute;
        newAttribute.id = 0;
        newAttribute.prefferedName = attributeSearchedValue;
        setSelectedAttribute(newAttribute);

        // props.createEntity(entity);
      } else if (!isNew) {
        // eslint-disable-next-line no-console
        console.log('update attribute', attribute, entity);
        props.updateEntity(entity);
      }
    }
  };

  React.useEffect(() => {
    if (!selectedAttribute?.id && selectedClassification?.id) {
      setDialogVariables({
        ...dialogVariables,
        dialogState: 'SEARCH',
        dialogTitle: 'Merkmal hinzufügen',
        dialogSaveButtonTitle: 'Hinzufügen',
      });
    }
    if (selectedAttribute?.id > 1 && selectedClassification?.id > 1) {
      setDialogVariables({
        ...dialogVariables,
        dialogState: 'ADD',
        dialogTitle: 'Ausgewählte Merkmal hinzufügen',
        dialogSaveButtonTitle: 'Hinzufügen',
      });

      // get Unit for the selectedClassification
      if (!selectedAttribute?.unit) {
        const requestUrl = encodeURI('api/units/irdiUN/' + encodeURIComponent(selectedAttribute?.irdiUN));
        axios.get(requestUrl).then(response => {
          const u: IUnit = response.data as IUnit;
          setSelectedAttribute({
            ...selectedAttribute,
            unit: u,
          });
          // eslint-disable-next-line no-console
          console.log('eclassAttributeModal set Unit', selectedAttribute, u);
        });
      }
    }
    if (selectedAttribute?.id === 1 && selectedClassification?.id) {
      setDialogVariables({
        ...dialogVariables,
        dialogState: 'CREATE',
        dialogTitle: 'Neue Merkmal erstellen',
        dialogSaveButtonTitle: 'Erstellen und hinzufügen',
      });
      setIsNew(true);
      selectedAttribute.prefferedName = attributeSearchedValue;
    }
  }, [selectedAttribute]);

  function handleSelect(e) {
    setSelectedAttribute(prev => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleMinMaxChange = (event, field) => {
    const { value, min, max } = event.target;
    // eslint-disable-next-line no-console
    console.log('handleMinMaxChange', event.target.value, field, Math.max(Number(min), Math.min(Number(max), Number(value))));
    event.target.value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    selectedAttribute[field] = event.target.value;
  };

  // dataType, definition, irdiPR, idPR, prefferedName
  return (
    <div>
      {attribute?.id ? (
        <span onClick={toggle}>
          <FontAwesomeIcon color="primary" icon="edit" size="lg" />
        </span>
      ) : (
        <Button color="primary" className="btn-sm" onClick={toggle}>
          {buttonLabel}
        </Button>
      )}

      <Modal isOpen={modal} toggle={toggle} className={className} size="lg" backdrop>
        <ModalHeader toggle={toggle}>
          {dialogVariables.dialogTitle}: <Badge color="secondary">{selectedAttribute?.irdiPR}</Badge>
        </ModalHeader>
        {selectedAttribute?.id ? (
          <ModalBody>
            <AvForm onSubmit={saveEntity}>
              <FormGroup row>
                <Label sm={3} for="prefferedName">
                  Merkmal:
                </Label>
                <Col sm={9}>
                  <Input
                    name="prefferedName"
                    id="prefferedName"
                    readOnly={selectedAttribute?.id && selectedAttribute?.id !== 1 && isNew}
                    defaultValue={selectedAttribute?.prefferedName}
                    onChange={node => (selectedAttribute.prefferedName = node.target.value)}
                  />
                </Col>
              </FormGroup>
              {selectedAttribute?.dataType && dialogVariables.dialogState !== 'CREATE' ? (
                <FormGroup row>
                  <Label sm={3} for="dataType">
                    Datenformat:
                  </Label>
                  <Col sm={9}>
                    <Input readOnly={!isNew} plaintext name="dataType" id="dataType" defaultValue={selectedAttribute?.dataType} />
                  </Col>
                </FormGroup>
              ) : (
                <FormGroup row>
                  <Label sm={3} for="dataType">
                    Datenformat:
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="dataType"
                      id="dataType"
                      type="select"
                      defaultValue={selectedAttribute != null ? selectedAttribute.dataType : 'STRING'}
                      onChange={handleSelect}
                    >
                      {selectedAttribute?.dataType && dialogVariables.dialogState === 'CREATE' ? (
                        <>
                          <option value="STRING">{translate('eclassApp.EclassDataType.STRING')}</option>
                          <option value="INTEGER_MEASURE">{translate('eclassApp.EclassDataType.INTEGER_MEASURE')}</option>
                          <option value="BOOLEAN">{translate('eclassApp.EclassDataType.BOOLEAN')}</option>
                          <option value="URL">{translate('eclassApp.EclassDataType.URL')}</option>
                          <option value="CLASSIFICATION_LIST">Klassifikationsliste</option>
                        </>
                      ) : (
                        <>
                          <option value="STRING">{translate('eclassApp.EclassDataType.STRING')}</option>
                          <option value="STRING_TRANSLATABLE">{translate('eclassApp.EclassDataType.STRING_TRANSLATABLE')}</option>
                          <option value="REAL_MEASURE">{translate('eclassApp.EclassDataType.REAL_MEASURE')}</option>
                          <option value="REAL_COUNT">{translate('eclassApp.EclassDataType.REAL_COUNT')}</option>
                          <option value="REAL_CURRENCY">{translate('eclassApp.EclassDataType.REAL_CURRENCY')}</option>
                          <option value="INTEGER_MEASURE">{translate('eclassApp.EclassDataType.INTEGER_MEASURE')}</option>
                          <option value="INTEGER_COUNT">{translate('eclassApp.EclassDataType.INTEGER_COUNT')}</option>
                          <option value="INTEGER_CURRENCY">{translate('eclassApp.EclassDataType.INTEGER_CURRENCY')}</option>
                          <option value="BOOLEAN">{translate('eclassApp.EclassDataType.BOOLEAN')}</option>
                          <option value="URL">{translate('eclassApp.EclassDataType.URL')}</option>
                          <option value="RATIONAL">{translate('eclassApp.EclassDataType.RATIONAL')}</option>
                          <option value="RATIONAL_MEASURE">{translate('eclassApp.EclassDataType.RATIONAL_MEASURE')}</option>
                          <option value="TIME">{translate('eclassApp.EclassDataType.TIME')}</option>
                          <option value="TIMESTAMP">{translate('eclassApp.EclassDataType.TIMESTAMP')}</option>
                          <option value="DATE">{translate('eclassApp.EclassDataType.DATE')}</option>
                        </>
                      )}
                    </Input>
                  </Col>
                </FormGroup>
              )}
              {selectedAttribute?.unit ? (
                <FormGroup row>
                  <Label sm={3} for="irdiUN">
                    Unit:
                  </Label>
                  <Col sm={9}>
                    <Input readOnly={!isNew} plaintext name="irdiUN" id="irdiUN" defaultValue={selectedAttribute?.unit?.shortName} />
                  </Col>
                </FormGroup>
              ) : (
                <React.Fragment>
                  {' '}
                  {selectedAttribute ? (
                    ''
                  ) : (
                    <FormGroup row>
                      <Label sm={3} for="irdiUN">
                        Unit:
                      </Label>
                      <Col sm={9}>
                        <Input name="irdiUN" id="irdiUN" value={selectedAttribute != null ? selectedAttribute.irdiUN : ''} />
                      </Col>
                    </FormGroup>
                  )}
                </React.Fragment>
              )}
              {['STRING', 'STRING_TRANSLATABLE', 'URL'].some(e => {
                return selectedAttribute?.dataType.includes(e);
              }) ? (
                <FormGroup row>
                  <Label sm={3} for="numberOfCharacters">
                    Länge:
                  </Label>
                  <Col sm={9}>
                    <Input
                      readOnly={readOnly}
                      name="numberOfCharacters"
                      id="numberOfCharacters"
                      type="number"
                      min="0"
                      max="1024"
                      onChange={e => handleMinMaxChange(e, 'numberOfCharacters')}
                      defaultValue={selectedAttribute?.numberOfCharacters}
                    />
                  </Col>
                </FormGroup>
              ) : (
                <></>
              )}
              {['INTEGER_MEASURE', 'INTEGER_COUNT', 'INTEGER_CURRENCY'].some(e => {
                return selectedAttribute?.dataType.includes(e);
              }) ? (
                <FormGroup row>
                  <Label sm={3} for="digitsBeforeComma">
                    Länge:
                  </Label>
                  <Col sm={9}>
                    <Input
                      readOnly={readOnly}
                      name="digitsBeforeComma"
                      id="digitsBeforeComma"
                      type="number"
                      min="0"
                      max="16"
                      onChange={e => handleMinMaxChange(e, 'digitsBeforeComma')}
                      defaultValue={selectedAttribute?.digitsBeforeComma}
                    />
                  </Col>
                </FormGroup>
              ) : (
                <></>
              )}
              {['REAL_MEASURE', 'REAL_COUNT', 'REAL_CURRENCY'].some(e => {
                return selectedAttribute?.dataType.includes(e);
              }) ? (
                <FormGroup row>
                  <Label sm={3} for="digitsBeforeComma">
                    Länge:
                  </Label>
                  <Col sm={1}>
                    <Input
                      readOnly={readOnly}
                      name="digitsBeforeComma"
                      id="digitsBeforeComma"
                      defaultValue={selectedAttribute?.digitsBeforeComma}
                      onChange={node => (selectedAttribute.digitsBeforeComma = node.target.value)}
                    />
                  </Col>
                  {selectedAttribute?.digitsAfterComma ? (
                    <>
                      <Label sm={1} for="digitsAfterComma">
                        ,
                      </Label>
                      <Col sm={1}>
                        <Input
                          readOnly={readOnly}
                          name="digitsAfterComma"
                          id="digitsAfterComma"
                          defaultValue={selectedAttribute?.digitsAfterComma}
                          onChange={node => (selectedAttribute.digitsAfterComma = node.target.value)}
                        />
                      </Col>
                    </>
                  ) : (
                    <></>
                  )}
                </FormGroup>
              ) : (
                <></>
              )}
              <FormGroup row>
                <Label sm={3} for="definition">
                  Definition:
                </Label>
                <Col sm={9}>
                  <Input
                    name="definition"
                    id="definition"
                    type="textarea"
                    readOnly={selectedAttribute?.id && isNew && dialogVariables.dialogState !== 'CREATE'}
                    defaultValue={selectedAttribute != null ? selectedAttribute.definition : ''}
                    onChange={node => (selectedAttribute.definition = node.target.value)}
                  />
                </Col>
              </FormGroup>
              {selectedAttribute ? (
                ''
              ) : (
                <FormGroup row>
                  <Label sm={3} for="idPR">
                    idPR:
                  </Label>
                  <Col sm={9}>
                    <Input name="idPR" id="idPR" defaultValue={selectedAttribute != null ? selectedAttribute.idPR : ''} />
                  </Col>
                </FormGroup>
              )}
              {selectedAttribute ? (
                ''
              ) : (
                <FormGroup row>
                  <Label sm={3} for="versionNumber">
                    Version:
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="versionNumber"
                      id="versionNumber"
                      defaultValue={selectedAttribute != null ? selectedAttribute.versionNumber : ''}
                    />
                  </Col>
                </FormGroup>
              )}
              {valueList != null && valueList.length > 0 ? (
                <FormGroup row>
                  <Label sm={3} for="values">
                    Werteliste:
                  </Label>
                  <Col sm={9}>
                    <Input
                      readOnly={!isNew}
                      name="values"
                      id="values"
                      type="select"
                      size="5"
                      defaultValue={selectedAttribute != null ? selectedAttribute.values : 'STRING'}
                    >
                      {valueList}
                    </Input>
                  </Col>
                </FormGroup>
              ) : (
                ''
              )}
              {selectedAttribute.dataType === 'CLASSIFICATION_LIST' ? (
                <FormGroup row>
                  <Label sm={3} for="definitionClass">
                    Klassifikation:
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="definitionClass"
                      id="definitionClass"
                      defaultValue={selectedAttribute != null ? selectedAttribute.definitionClass : ''}
                      onChange={node => (selectedAttribute.definitionClass = node.target.value)}
                    />
                  </Col>
                </FormGroup>
              ) : (
                ''
              )}
              {attribute || dialogVariables.dialogState === 'CREATE' ? (
                ''
              ) : (
                <FormGroup row>
                  <Label sm={3} for="supplier">
                    Quelle ID:
                  </Label>
                  <Col sm={9}>
                    <Input
                      name="supplier"
                      readOnly={selectedAttribute?.id && isNew}
                      id="supplier"
                      defaultValue={selectedAttribute != null ? selectedAttribute.supplier : ''}
                    />
                  </Col>
                </FormGroup>
              )}
              <Button color="primary" type="submit" onClick={addAttributeToClassification}>
                {dialogVariables.dialogSaveButtonTitle}
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Abbrechen
              </Button>
              {selectedAttribute.dataType === 'XXCLASSIFICATION_LIST' ? (
                <>
                  {selectedAttribute?.id} <>C</> {selectedClassification?.id} <>?</> {String(isNew)} <>=</> {selectedAttribute?.dataType}{' '}
                </>
              ) : (
                ''
              )}
            </AvForm>
          </ModalBody>
        ) : (
          <ModalBody>
            <AvForm onSubmit={saveEntity}>
              <FormGroup row>
                <Label sm={3} for="prefferedName">
                  Merkmal Suchen:
                </Label>
                <Col sm={9}>
                  <span className="EclassAttributeSearchBox">
                    <EclassAttributeAddSearchBox selectAttribute={setSelectedAttribute} searchedValue={setAttributeSearchedValue} />
                  </span>
                </Col>
              </FormGroup>
              <Button color="primary" type="submit" onClick={addAttributeToClassification}>
                {dialogVariables.dialogSaveButtonTitle}
              </Button>{' '}
              <Button color="secondary" onClick={toggle}>
                Abbrechen
              </Button>
            </AvForm>
          </ModalBody>
        )}
        <ModalFooter></ModalFooter>
      </Modal>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState, props) => ({
  attributeEntity: storeState.attribute.entity,
  loading: storeState.attribute.loading,
  updating: storeState.attribute.updating,
  updateSuccess: storeState.attribute.updateSuccess,
  buttonLabel: props.buttonLabel,
  className: props.className,
  attribute: props.attribute,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EclassAttributeModal);
