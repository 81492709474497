import { TextFormat } from 'react-jhipster';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import React, { useState } from 'react';
import { Card, CardBody, CardFooter, Container, CardTitle, Row, Col } from 'reactstrap';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import { set } from 'lodash';
import { useEclassState, EclassStateInterface } from './eclass-state-provider';
import './eclass-attribute-add-search-box.scss';
const apiUrl = 'api/attributes';

export const EclassAttributeAddSearchBox = props => {
  const { selectAttribute, searchedValue } = props;
  const [value, setValue] = useState('');
  const [selectedValue, setSelectedValue] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const { setState } = useEclassState();
  const { state } = useEclassState();

  function getSuggestions(searchValue) {
    if (searchValue.length < 3) {
      return;
    }
    if (selectedValue) {
      return;
    }
    // get the list of attributes
    let requestUrl = encodeURI(`${apiUrl}/search?searchString=` + searchValue);
    // handle the # in the irdi
    if (searchValue.startsWith('0173-1')) {
      requestUrl = encodeURI(`${apiUrl}/search?searchString=` + encodeURIComponent(searchValue));
    }
    axios.get(requestUrl).then(response => {
      const returnList = response.data;
      setSuggestions(returnList);
      return returnList;
    });
  }

  function addSingleAttributes(attribute) {
    const data = {
      attributeListToAdd: [],
    };
    if (state.attributeListToAdd) {
      data.attributeListToAdd = state.attributeListToAdd;
    }
    data.attributeListToAdd.push(attribute);
    // and update the state with the list to add
    setState(prev => ({ ...prev, ...data }));
  }
  
  React.useEffect(() => {
    if (selectedValue) {

      setSelectedValue(null);   
      selectAttribute(selectedValue);
      setValue('');
    };
          // eslint-disable-next-line no-console
       console.log('eclass-attribute-add-search-box selectedValue', selectedValue);
  }, [selectedValue]);

  return (
    <React.Fragment>
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsClearRequested={() => setSuggestions([])}
        // eslint-disable-next-line no-shadow
        onSuggestionsFetchRequested={({ value }) => {
          setValue(value);
          getSuggestions(value);
        }}
        onSuggestionSelected={(_, { suggestionValue }) => {
          setSelectedValue(suggestionValue);
                          // eslint-disable-next-line no-console
                          console.log('eclass-attribute-add-search-box suggestionValue', suggestionValue);
                          searchedValue(null);
        }}
        getSuggestionValue={suggestion => suggestion}
        inputProps={{
          placeholder: 'Merkmal suchen ...',
          // eslint-disable-next-line object-shorthand
          value: value,
          onChange(_, { newValue, method }) {
            if (!newValue) {
              setValue('');
            } else {
                // eslint-disable-next-line no-console
                console.log('eclass-attribute-add-search-box newValue', newValue);
                searchedValue(newValue);
            }
          },
        }}
        highlightFirstSuggestion={true}
        renderSuggestion={suggestion => <div className="float-left">{suggestion.prefferedName}</div>}
      />
    </React.Fragment>
  );
};

export default EclassAttributeAddSearchBox;
