import { IClassification } from 'app/shared/model/classification.model';
import { IAttribute } from 'app/shared/model/attribute.model';
import { IEclassValue } from 'app/shared/model/eclass-value.model';

export interface IClassificationAttributeValueXref {
  id?: number;
  constraint?: boolean;
  irdiCC?: IClassification;
  irdiPR?: IAttribute;
  irdiVA?: IEclassValue;
}

export const defaultValue: Readonly<IClassificationAttributeValueXref> = {
  constraint: false,
};
