import { Moment } from 'moment';
import { ISpecificationAttributeData } from 'app/shared/model/specification-attribute-data.model';
import { IClassification } from 'app/shared/model/classification.model';
import { IUser } from 'app/shared/model/user.model';

export interface ISpecification {
  id?: number;
  idSD?: string;
  irdiSD?: string;
  identifier?: string;
  supplier?: string;
  prefferedName?: string;
  definition?: string;
  note?: string;
  versionNumber?: number;
  versionDate?: string;
  revisionNumber?: number;
  specificationAttributeData?: ISpecificationAttributeData[];
  irdiCC?: IClassification;
  user?: IUser;
}

export const defaultValue: Readonly<ISpecification> = {};
