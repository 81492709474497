import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col } from 'reactstrap';
import { Translate, ICrudGetAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import { getEntity } from './classification-attribute-xref.reducer';
import { IClassificationAttributeXref } from 'app/shared/model/classification-attribute-xref.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';

export interface IClassificationAttributeXrefDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const ClassificationAttributeXrefDetail = (props: IClassificationAttributeXrefDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);

  const { classificationAttributeXrefEntity } = props;
  return (
    <Row>
      <Col md="8">
        <h2>
          <Translate contentKey="eclassApp.classificationAttributeXref.detail.title">ClassificationAttributeXref</Translate> [
          <b>{classificationAttributeXrefEntity.id}</b>]
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="supplierIdCC">
              <Translate contentKey="eclassApp.classificationAttributeXref.supplierIdCC">Supplier Id CC</Translate>
            </span>
            <UncontrolledTooltip target="supplierIdCC">
              <Translate contentKey="eclassApp.classificationAttributeXref.help.supplierIdCC" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationAttributeXrefEntity.supplierIdCC}</dd>
          <dt>
            <span id="idCC">
              <Translate contentKey="eclassApp.classificationAttributeXref.idCC">Id CC</Translate>
            </span>
            <UncontrolledTooltip target="idCC">
              <Translate contentKey="eclassApp.classificationAttributeXref.help.idCC" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationAttributeXrefEntity.idCC}</dd>
          <dt>
            <span id="classCodedName">
              <Translate contentKey="eclassApp.classificationAttributeXref.classCodedName">Class Coded Name</Translate>
            </span>
            <UncontrolledTooltip target="classCodedName">
              <Translate contentKey="eclassApp.classificationAttributeXref.help.classCodedName" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationAttributeXrefEntity.classCodedName}</dd>
          <dt>
            <span id="supplierIdPR">
              <Translate contentKey="eclassApp.classificationAttributeXref.supplierIdPR">Supplier Id PR</Translate>
            </span>
            <UncontrolledTooltip target="supplierIdPR">
              <Translate contentKey="eclassApp.classificationAttributeXref.help.supplierIdPR" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationAttributeXrefEntity.supplierIdPR}</dd>
          <dt>
            <span id="idPR">
              <Translate contentKey="eclassApp.classificationAttributeXref.idPR">Id PR</Translate>
            </span>
            <UncontrolledTooltip target="idPR">
              <Translate contentKey="eclassApp.classificationAttributeXref.help.idPR" />
            </UncontrolledTooltip>
          </dt>
          <dd>{classificationAttributeXrefEntity.idPR}</dd>
          <dt>
            <Translate contentKey="eclassApp.classificationAttributeXref.irdiCC">Irdi CC</Translate>
          </dt>
          <dd>{classificationAttributeXrefEntity.irdiCC ? classificationAttributeXrefEntity.irdiCC.irdiCC : ''}</dd>
          <dt>
            <Translate contentKey="eclassApp.classificationAttributeXref.irdiPR">Irdi PR</Translate>
          </dt>
          <dd>{classificationAttributeXrefEntity.irdiPR ? classificationAttributeXrefEntity.irdiPR.irdiPR : ''}</dd>
        </dl>
        <Button tag={Link} to="/classification-attribute-xref" replace color="info">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/classification-attribute-xref/${classificationAttributeXrefEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

const mapStateToProps = ({ classificationAttributeXref }: IRootState) => ({
  classificationAttributeXrefEntity: classificationAttributeXref.entity,
});

const mapDispatchToProps = { getEntity };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationAttributeXrefDetail);
