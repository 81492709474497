import { IAttribute } from "app/shared/model/attribute.model"
import React, { useState, useContext, createContext, Dispatch, SetStateAction } from "react"

// followed this example
// https://jamiehaywood.medium.com/typesafe-global-state-with-typescript-react-react-context-c2df743f3ce

export interface EclassStateInterface {
    attributeListToAdd: Array<IAttribute>;
    tempAttributeListToAdd: Array<IAttribute>;
    specificationIsDirty: boolean;
    specificationInProgress: boolean;
}

export const EclassStateContext = React.createContext({
  state: {} as Partial<EclassStateInterface>,
  setState: {} as Dispatch<SetStateAction<Partial<EclassStateInterface>>>,
});

const EclassStateProvider = ({
    children,
    value = {} as EclassStateInterface,
}: {
    children: React.ReactNode;
    value?: Partial<EclassStateInterface>;
}) => {
    const [state, setState] = useState(value);
    return (
      <EclassStateContext.Provider value={{ state, setState }}>
        {children}
      </EclassStateContext.Provider>
    );
};

const useEclassState = () => {
    const context = useContext(EclassStateContext);
    if (!context) {
      throw new Error("useGlobalState must be used within a EclassStateContext");
    }
    return context;
  };
  
  export { EclassStateProvider, useEclassState };