import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Label, UncontrolledTooltip } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { Translate, translate, ICrudGetAction, ICrudGetAllAction, ICrudPutAction } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { getEntity, updateEntity, createEntity, reset } from './attribute.reducer';
import { IAttribute } from 'app/shared/model/attribute.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';

export interface IAttributeUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const AttributeUpdate = (props: IAttributeUpdateProps) => {
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);

  const { attributeEntity, loading, updating } = props;

  const handleClose = () => {
    props.history.push('/attribute' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    if (errors.length === 0) {
      const entity = {
        ...attributeEntity,
        ...values,
      };

      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="eclassApp.attribute.home.createOrEditLabel">
            <Translate contentKey="eclassApp.attribute.home.createOrEditLabel">Create or edit a Attribute</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : attributeEntity} onSubmit={saveEntity}>
              {!isNew ? (
                <AvGroup>
                  <Label for="attribute-id">
                    <Translate contentKey="global.field.id">ID</Translate>
                  </Label>
                  <AvInput id="attribute-id" type="text" className="form-control" name="id" required readOnly />
                </AvGroup>
              ) : null}
              <AvGroup>
                <Label id="supplierLabel" for="attribute-supplier">
                  <Translate contentKey="eclassApp.attribute.supplier">Supplier</Translate>
                </Label>
                <AvField
                  id="attribute-supplier"
                  type="text"
                  name="supplier"
                  validate={{
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="supplierLabel">
                  <Translate contentKey="eclassApp.attribute.help.supplier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="idPRLabel" for="attribute-idPR">
                  <Translate contentKey="eclassApp.attribute.idPR">Id PR</Translate>
                </Label>
                <AvField
                  id="attribute-idPR"
                  type="text"
                  name="idPR"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 9, errorMessage: translate('entity.validation.maxlength', { max: 9 }) },
                  }}
                />
                <UncontrolledTooltip target="idPRLabel">
                  <Translate contentKey="eclassApp.attribute.help.idPR" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="identifierLabel" for="attribute-identifier">
                  <Translate contentKey="eclassApp.attribute.identifier">Identifier</Translate>
                </Label>
                <AvField
                  id="attribute-identifier"
                  type="text"
                  name="identifier"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 6, errorMessage: translate('entity.validation.maxlength', { max: 6 }) },
                  }}
                />
                <UncontrolledTooltip target="identifierLabel">
                  <Translate contentKey="eclassApp.attribute.help.identifier" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionNumberLabel" for="attribute-versionNumber">
                  <Translate contentKey="eclassApp.attribute.versionNumber">Version Number</Translate>
                </Label>
                <AvField id="attribute-versionNumber" type="string" className="form-control" name="versionNumber" />
                <UncontrolledTooltip target="versionNumberLabel">
                  <Translate contentKey="eclassApp.attribute.help.versionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="versionDateLabel" for="attribute-versionDate">
                  <Translate contentKey="eclassApp.attribute.versionDate">Version Date</Translate>
                </Label>
                <AvField id="attribute-versionDate" type="date" className="form-control" name="versionDate" />
                <UncontrolledTooltip target="versionDateLabel">
                  <Translate contentKey="eclassApp.attribute.help.versionDate" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="revisionNumberLabel" for="attribute-revisionNumber">
                  <Translate contentKey="eclassApp.attribute.revisionNumber">Revision Number</Translate>
                </Label>
                <AvField id="attribute-revisionNumber" type="string" className="form-control" name="revisionNumber" />
                <UncontrolledTooltip target="revisionNumberLabel">
                  <Translate contentKey="eclassApp.attribute.help.revisionNumber" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="prefferedNameLabel" for="attribute-prefferedName">
                  <Translate contentKey="eclassApp.attribute.prefferedName">Preffered Name</Translate>
                </Label>
                <AvField
                  id="attribute-prefferedName"
                  type="text"
                  name="prefferedName"
                  validate={{
                    maxLength: { value: 80, errorMessage: translate('entity.validation.maxlength', { max: 80 }) },
                  }}
                />
                <UncontrolledTooltip target="prefferedNameLabel">
                  <Translate contentKey="eclassApp.attribute.help.prefferedName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="shortNameLabel" for="attribute-shortName">
                  <Translate contentKey="eclassApp.attribute.shortName">Short Name</Translate>
                </Label>
                <AvField
                  id="attribute-shortName"
                  type="text"
                  name="shortName"
                  validate={{
                    maxLength: { value: 17, errorMessage: translate('entity.validation.maxlength', { max: 17 }) },
                  }}
                />
                <UncontrolledTooltip target="shortNameLabel">
                  <Translate contentKey="eclassApp.attribute.help.shortName" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionLabel" for="attribute-definition">
                  <Translate contentKey="eclassApp.attribute.definition">Definition</Translate>
                </Label>
                <AvField
                  id="attribute-definition"
                  type="text"
                  name="definition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionLabel">
                  <Translate contentKey="eclassApp.attribute.help.definition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="sourceOfDefinitionLabel" for="attribute-sourceOfDefinition">
                  <Translate contentKey="eclassApp.attribute.sourceOfDefinition">Source Of Definition</Translate>
                </Label>
                <AvField
                  id="attribute-sourceOfDefinition"
                  type="text"
                  name="sourceOfDefinition"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="sourceOfDefinitionLabel">
                  <Translate contentKey="eclassApp.attribute.help.sourceOfDefinition" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="noteLabel" for="attribute-note">
                  <Translate contentKey="eclassApp.attribute.note">Note</Translate>
                </Label>
                <AvField
                  id="attribute-note"
                  type="text"
                  name="note"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="noteLabel">
                  <Translate contentKey="eclassApp.attribute.help.note" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="remarkLabel" for="attribute-remark">
                  <Translate contentKey="eclassApp.attribute.remark">Remark</Translate>
                </Label>
                <AvField
                  id="attribute-remark"
                  type="text"
                  name="remark"
                  validate={{
                    maxLength: { value: 1024, errorMessage: translate('entity.validation.maxlength', { max: 1024 }) },
                  }}
                />
                <UncontrolledTooltip target="remarkLabel">
                  <Translate contentKey="eclassApp.attribute.help.remark" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="formularSymbolLabel" for="attribute-formularSymbol">
                  <Translate contentKey="eclassApp.attribute.formularSymbol">Formular Symbol</Translate>
                </Label>
                <AvField
                  id="attribute-formularSymbol"
                  type="text"
                  name="formularSymbol"
                  validate={{
                    maxLength: { value: 17, errorMessage: translate('entity.validation.maxlength', { max: 17 }) },
                  }}
                />
                <UncontrolledTooltip target="formularSymbolLabel">
                  <Translate contentKey="eclassApp.attribute.help.formularSymbol" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiUNLabel" for="attribute-irdiUN">
                  <Translate contentKey="eclassApp.attribute.irdiUN">Irdi UN</Translate>
                </Label>
                <AvField
                  id="attribute-irdiUN"
                  type="text"
                  name="irdiUN"
                  validate={{
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiUNLabel">
                  <Translate contentKey="eclassApp.attribute.help.irdiUN" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOLanguageCodeLabel" for="attribute-iSOLanguageCode">
                  <Translate contentKey="eclassApp.attribute.iSOLanguageCode">I SO Language Code</Translate>
                </Label>
                <AvField
                  id="attribute-iSOLanguageCode"
                  type="text"
                  name="iSOLanguageCode"
                  validate={{
                    maxLength: { value: 2, errorMessage: translate('entity.validation.maxlength', { max: 2 }) },
                  }}
                />
                <UncontrolledTooltip target="iSOLanguageCodeLabel">
                  <Translate contentKey="eclassApp.attribute.help.iSOLanguageCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="iSOCountryCodeLabel" for="attribute-iSOCountryCode">
                  <Translate contentKey="eclassApp.attribute.iSOCountryCode">I SO Country Code</Translate>
                </Label>
                <AvInput
                  id="attribute-iSOCountryCode"
                  type="select"
                  className="form-control"
                  name="iSOCountryCode"
                  value={(!isNew && attributeEntity.iSOCountryCode) || 'DE'}
                >
                  <option value="DE">{translate('eclassApp.ISOCountryCode.DE')}</option>
                  <option value="US">{translate('eclassApp.ISOCountryCode.US')}</option>
                </AvInput>
                <UncontrolledTooltip target="iSOCountryCodeLabel">
                  <Translate contentKey="eclassApp.attribute.help.iSOCountryCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="categoryLabel" for="attribute-category">
                  <Translate contentKey="eclassApp.attribute.category">Category</Translate>
                </Label>
                <AvField
                  id="attribute-category"
                  type="text"
                  name="category"
                  validate={{
                    maxLength: { value: 3, errorMessage: translate('entity.validation.maxlength', { max: 3 }) },
                  }}
                />
                <UncontrolledTooltip target="categoryLabel">
                  <Translate contentKey="eclassApp.attribute.help.category" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="attributeTypeLabel" for="attribute-attributeType">
                  <Translate contentKey="eclassApp.attribute.attributeType">Attribute Type</Translate>
                </Label>
                <AvInput
                  id="attribute-attributeType"
                  type="select"
                  className="form-control"
                  name="attributeType"
                  value={(!isNew && attributeEntity.attributeType) || 'DIRECT'}
                >
                  <option value="DIRECT">{translate('eclassApp.EclassAttributeType.DIRECT')}</option>
                  <option value="INDIRECT">{translate('eclassApp.EclassAttributeType.INDIRECT')}</option>
                </AvInput>
                <UncontrolledTooltip target="attributeTypeLabel">
                  <Translate contentKey="eclassApp.attribute.help.attributeType" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="definitionClassLabel" for="attribute-definitionClass">
                  <Translate contentKey="eclassApp.attribute.definitionClass">Definition Class</Translate>
                </Label>
                <AvField
                  id="attribute-definitionClass"
                  type="text"
                  name="definitionClass"
                  validate={{
                    maxLength: { value: 255, errorMessage: translate('entity.validation.maxlength', { max: 255 }) },
                  }}
                />
                <UncontrolledTooltip target="definitionClassLabel">
                  <Translate contentKey="eclassApp.attribute.help.definitionClass" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="dataTypeLabel" for="attribute-dataType">
                  <Translate contentKey="eclassApp.attribute.dataType">Data Type</Translate>
                </Label>
                <AvInput
                  id="attribute-dataType"
                  type="select"
                  className="form-control"
                  name="dataType"
                  value={(!isNew && attributeEntity.dataType) || 'STRING'}
                >
                  <option value="STRING">{translate('eclassApp.EclassDataType.STRING')}</option>
                  <option value="STRING_TRANSLATABLE">{translate('eclassApp.EclassDataType.STRING_TRANSLATABLE')}</option>
                  <option value="REAL_MEASURE">{translate('eclassApp.EclassDataType.REAL_MEASURE')}</option>
                  <option value="REAL_COUNT">{translate('eclassApp.EclassDataType.REAL_COUNT')}</option>
                  <option value="REAL_CURRENCY">{translate('eclassApp.EclassDataType.REAL_CURRENCY')}</option>
                  <option value="INTEGER_MEASURE">{translate('eclassApp.EclassDataType.INTEGER_MEASURE')}</option>
                  <option value="INTEGER_COUNT">{translate('eclassApp.EclassDataType.INTEGER_COUNT')}</option>
                  <option value="INTEGER_CURRENCY">{translate('eclassApp.EclassDataType.INTEGER_CURRENCY')}</option>
                  <option value="BOOLEAN">{translate('eclassApp.EclassDataType.BOOLEAN')}</option>
                  <option value="URL">{translate('eclassApp.EclassDataType.URL')}</option>
                  <option value="RATIONAL">{translate('eclassApp.EclassDataType.RATIONAL')}</option>
                  <option value="RATIONAL_MEASURE">{translate('eclassApp.EclassDataType.RATIONAL_MEASURE')}</option>
                  <option value="TIME">{translate('eclassApp.EclassDataType.TIME')}</option>
                  <option value="TIMESTAMP">{translate('eclassApp.EclassDataType.TIMESTAMP')}</option>
                  <option value="DATE">{translate('eclassApp.EclassDataType.DATE')}</option>
                </AvInput>
                <UncontrolledTooltip target="dataTypeLabel">
                  <Translate contentKey="eclassApp.attribute.help.dataType" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="digitsBeforeCommaLabel" for="attribute-digitsBeforeComma">
                  <Translate contentKey="eclassApp.attribute.digitsBeforeComma">Digits Before Comma</Translate>
                </Label>
                <AvField id="attribute-digitsBeforeComma" type="string" className="form-control" name="digitsBeforeComma" />
                <UncontrolledTooltip target="digitsBeforeCommaLabel">
                  <Translate contentKey="eclassApp.attribute.help.digitsBeforeComma" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="digitsAfterCommaLabel" for="attribute-digitsAfterComma">
                  <Translate contentKey="eclassApp.attribute.digitsAfterComma">Digits After Comma</Translate>
                </Label>
                <AvField id="attribute-digitsAfterComma" type="string" className="form-control" name="digitsAfterComma" />
                <UncontrolledTooltip target="digitsAfterCommaLabel">
                  <Translate contentKey="eclassApp.attribute.help.digitsAfterComma" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="numberOfCharactersLabel" for="attribute-numberOfCharacters">
                  <Translate contentKey="eclassApp.attribute.numberOfCharacters">Number Of Characters</Translate>
                </Label>
                <AvField id="attribute-numberOfCharacters" type="string" className="form-control" name="numberOfCharacters" />
                <UncontrolledTooltip target="numberOfCharactersLabel">
                  <Translate contentKey="eclassApp.attribute.help.numberOfCharacters" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="irdiPRLabel" for="attribute-irdiPR">
                  <Translate contentKey="eclassApp.attribute.irdiPR">Irdi PR</Translate>
                </Label>
                <AvField
                  id="attribute-irdiPR"
                  type="text"
                  name="irdiPR"
                  validate={{
                    required: { value: true, errorMessage: translate('entity.validation.required') },
                    maxLength: { value: 20, errorMessage: translate('entity.validation.maxlength', { max: 20 }) },
                  }}
                />
                <UncontrolledTooltip target="irdiPRLabel">
                  <Translate contentKey="eclassApp.attribute.help.irdiPR" />
                </UncontrolledTooltip>
              </AvGroup>
              <AvGroup>
                <Label id="currencyAlphaCodeLabel" for="attribute-currencyAlphaCode">
                  <Translate contentKey="eclassApp.attribute.currencyAlphaCode">Currency Alpha Code</Translate>
                </Label>
                <AvField
                  id="attribute-currencyAlphaCode"
                  type="text"
                  name="currencyAlphaCode"
                  validate={{
                    maxLength: { value: 3, errorMessage: translate('entity.validation.maxlength', { max: 3 }) },
                  }}
                />
                <UncontrolledTooltip target="currencyAlphaCodeLabel">
                  <Translate contentKey="eclassApp.attribute.help.currencyAlphaCode" />
                </UncontrolledTooltip>
              </AvGroup>
              <Button tag={Link} id="cancel-save" to="/attribute" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </AvForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  attributeEntity: storeState.attribute.entity,
  loading: storeState.attribute.loading,
  updating: storeState.attribute.updating,
  updateSuccess: storeState.attribute.updateSuccess,
});

const mapDispatchToProps = {
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AttributeUpdate);
