export const enum EclassDataType {
  STRING = 'STRING',

  STRING_TRANSLATABLE = 'STRING_TRANSLATABLE',

  REAL_MEASURE = 'REAL_MEASURE',

  REAL_COUNT = 'REAL_COUNT',

  REAL_CURRENCY = 'REAL_CURRENCY',

  INTEGER_MEASURE = 'INTEGER_MEASURE',

  INTEGER_COUNT = 'INTEGER_COUNT',

  INTEGER_CURRENCY = 'INTEGER_CURRENCY',

  BOOLEAN = 'BOOLEAN',

  URL = 'URL',

  RATIONAL = 'RATIONAL',

  RATIONAL_MEASURE = 'RATIONAL_MEASURE',

  TIME = 'TIME',

  TIMESTAMP = 'TIMESTAMP',

  DATE = 'DATE',
}
