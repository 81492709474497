import React, { useState, createRef } from 'react';
import axios from 'axios';
import { Form, FormGroup, CardHeader, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SelectSearch from 'react-select-search';
import { ISpecification } from 'app/shared/model/specification.model';
import { useEclassState, EclassStateInterface } from './eclass-state-provider';
import EclassCancelChangeModal from './eclass-cancel-change-modal';

const apiUrl = 'api/specifications';

export const EclassSpecificationSearch = props => {
  const [specificationList, setSpecificationList] = useState(null);
  const { selectedClassification, changeSelectedSpecification, resetSpecificationSearch, setDirtySpecificationForm, submitForm } = props;
  const [specificationSelectList, setSpecificationSelectList] = useState([]);
  const [selectedSpecificationId, setSelectedSpecificationId] = useState(null);
  const [selectedSpecification, setSelectedSpecification] = useState(null);
  const [selectedSpecificationIndex, setSelectedSpecificationIndex] = useState(null);
  const [tempSpecificationList, setTempSpecificationList] = useState([]);
  const [tempQueryString, setTempQueryString] = useState(null);
  const { setState } = useEclassState();
  const { state } = useEclassState();
  const [modal, setModal] = useState(false);
  //  const [savedId, setSavedId] = useState(null);

  const selectSearchRef = createRef<React.Component<{}, {}, any>>();

  const newSpecification = {
    prefferedName: 'neues Stammdatenblatt',
    irdiCC: selectedClassification,
    irdiSD: 'neues Stammdatenblatt',
    idSD: '',
    id: 0,
  };

  const toggleSaveModal = () => {
    setModal(!modal);
  };

  const goToPrevious = () => {
    if (state.specificationIsDirty) {
      toggleSaveModal();
    } else {
      for (let i = 1; i < specificationList.length; i += 1) {
        if (specificationList[i].id === selectedSpecificationId) {
          setSelectedSpecificationId(specificationList[i - 1].id);
        }
      }
    }
  };

  const cancelSaveModal = () => {
    if (selectedSpecificationIndex > 0) {
      specificationList.pop();
      if (specificationList.length > -1) {
        setSelectedSpecification(specificationList[specificationList.length - 1]);
        setSelectedSpecificationId(specificationList[specificationList.length - 1].id);
        setSelectedSpecificationIndex(specificationList.length - 1);
      }
    }
    setModal(!modal);
  };

  const rerender = position => {
    if (selectedClassification?.irdiCC) {
      const requestUrl = encodeURI(`${apiUrl}/classification/` + encodeURIComponent(selectedClassification.irdiCC) + `?searchString=`);
      axios.get<ISpecification[]>(requestUrl).then(response => {
        setSpecificationSelectList(
          response?.data
            ?.map(function (specification) {
              if (specification.versionNumber === 0) {
                const dateOfVersion = new Intl.DateTimeFormat('de-DE', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }).format(new Date(specification.versionDate));
                return { value: specification.id, name: specification.irdiSD + '  (' + dateOfVersion + ')' };
              } else {
                return { value: specification.id, name: specification.irdiSD + '  (in bearbeitung)' };
              }
            })
            .concat({ value: newSpecification.id, name: newSpecification.irdiSD })
        );

        setSpecificationList(response.data);
        if (position === 0 && response.data && response.data.length > 0) {
          setSelectedSpecificationId(response.data[0].id);
          setSelectedSpecification(response.data[0]);
        } else if (position && response.data && response.data.length > 0) {
          const specification = response.data.find(e => {
            return e.identifier === position;
          });
          setSelectedSpecificationId(null);
          if (specification) {
            setSelectedSpecificationId(specification.id);
            setSelectedSpecification(specification);
          } else {
            // handle if deleted, or we can'T find, so jump to first item in list
            setSelectedSpecificationId(response.data[0].id);
            setSelectedSpecification(response.data[0]);
          }
        }
      });
    }
  };

  /*   React.useEffect(() => {
    if (savedId && specificationList) {
      // so just in case ID changes - swap out the object at that position?
      if (savedId === 0 && specificationList && specificationList.length > 0) {
        setSelectedSpecificationId(specificationList[0].id);
        setSelectedSpecification(specificationList[0]);
      } else if (savedId > 0 && specificationList && specificationList.length > 0) {
        const specification = specificationList.find(e => {
          return e.id === savedId;
        });
        if (specification) {
          setSelectedSpecificationId(specification.id);
          setSelectedSpecification(specification);
        } else {
          // handle if deleted, or we can'T find, so jump to first item in list
          setSelectedSpecificationId(specificationList.id);
          setSelectedSpecification(specificationList[0]);
        }
      }
    }
  }, [specificationList]);
 */
  React.useEffect(() => {
    const reset = rerender(0);
  }, [selectedClassification]);

  React.useEffect(() => {
    rerender(resetSpecificationSearch);
  }, [resetSpecificationSearch]);

  React.useEffect(() => {
    if (specificationList) {
      setSelectedSpecification(specificationList.find(o => o.id === selectedSpecificationId));
      for (let i = 0; i < specificationList.length; i += 1) {
        if (specificationList[i].id === selectedSpecificationId) {
          setSelectedSpecificationIndex(i);
        }
      }
      setDirtySpecificationForm(false);
    }
    if (selectSearchRef) {
      selectSearchRef?.current['classList'].remove('is-loading');
    }
  }, [selectedSpecificationId]);

  React.useEffect(() => {
    changeSelectedSpecification(selectedSpecification);
  }, [selectedSpecification]);

  const goToNext = () => {
    if (state.specificationIsDirty) {
      toggleSaveModal();
    } else {
      for (let i = 0; i < specificationList.length; i += 1) {
        if (specificationList[i].id === selectedSpecificationId) {
          if (i + 1 < specificationList.length) {
            setSelectedSpecificationId(specificationList[i + 1].id);
          }
        }
      }
    }
  };

  // here is information regarding canceling adding a new one
  const addNewSpecification = () => {
    if (state.specificationIsDirty) {
      toggleSaveModal();
    } else {
      specificationList.push(newSpecification);
      setSelectedSpecificationId(0);
    }
  };

  function changeSelection(value) {
    if (state.specificationIsDirty) {
      toggleSaveModal();
    } else {
      if (value === 0) {
        specificationList.push(newSpecification);
      }
      // setSpecificationList(tempSpecificationList);
      setSelectedSpecificationId(value);
    }
  }

  function queryNewValues(query, resolve, reject) {
    if (state.specificationIsDirty) {
      toggleSaveModal();
    } else {
      if (selectedClassification && query !== tempQueryString) {
        const requestUrl = encodeURI(
          `${apiUrl}/classification/` + encodeURIComponent(selectedClassification.irdiCC) + `?searchString=` + query
        );
        axios
          .get<ISpecification[]>(requestUrl)
          .then(response => {
            resolve(
              response?.data
                ?.map(function (specification) {
                  return { value: specification.id, name: query + ': ' + specification.irdiSD };
                })
                .concat({ value: newSpecification.id, name: newSpecification.irdiSD })
            );
            setTempQueryString(query);

            setTempSpecificationList(response.data);
          })
          .catch(reject);
      }
    }
  }

  return (
    <React.Fragment>
      <CardHeader tag="h4">
        Stammdatenblatt
        {specificationList?.length > 0 ? (
          <>
            <div className="badge">
              ({selectedSpecificationIndex + 1} / {specificationList?.length}){selectedClassification ? ' ' : ' '}
            </div>
          </>
        ) : (
          ''
        )}{' '}
        &nbsp;
        <Button color="primary" size="sm" onClick={addNewSpecification}>
          <FontAwesomeIcon icon="plus-circle" /> neues anlegen
        </Button>
        <EclassCancelChangeModal modal={modal} toggle={toggleSaveModal} cancel={cancelSaveModal} submitForm={submitForm} />
        <div className="float-right" style={{ marginRight: '5px' }}>
          <Button color="secondary" size="sm" onClick={goToPrevious}>
            <FontAwesomeIcon icon="chevron-circle-left" />
          </Button>
          <Button color="secondary" size="sm" onClick={goToNext}>
            <FontAwesomeIcon icon="chevron-circle-right" />
          </Button>
        </div>
      </CardHeader>
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        <FormGroup>
          <SelectSearch
            options={specificationSelectList}
            onChange={changeSelection}
            ref={selectSearchRef}
            search={true}
            placeholder="Stammdatenblatt auswählen"
            value={selectedSpecificationId}
            debounce={500}
            getOptions={query => {
              return new Promise((resolve, reject) => {
                if (query) {
                  // eslint-disable-next-line no-console
                  console.log('eclass-specification-search: getOptions', query);
                  queryNewValues(query, resolve, reject);
                }
                // queryNewValues(query, resolve, reject);
              });
            }}
          />
        </FormGroup>
      </Form>
    </React.Fragment>
  );
};

export default EclassSpecificationSearch;
